import styled from 'styled-components'

import { Button as InterButton } from '@interco/inter-ui/components/Button'
import { SelectBottomSheet } from '@interco/inter-ui/components/SelectBottomSheet'

export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`
export const Select = styled.div`
  height: 48px;
  width: 100%;
  overflow-y: hidden;
  margin: 24px 0 40px;
`

export const SelectInput = styled(SelectBottomSheet)`
  margin-top: -20px;
  width: 100%;
`
