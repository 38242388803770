import styled from 'styled-components'

import { ContainerColum } from '../Containers'
import { Label, LabelTitleHeadline1 } from '../Labels'

const circle = styled.div`
  padding: 16px;
  width: 64px;
  height: 64px;
  border-radius: 60px;
`

export const YellowCircle = styled(circle)`
  background: var(--alert500);
`

export const GreenCircle = styled(circle)`
  background: var(--success500);
`

export const RedCircle = styled(circle)`
  background: var(--error500);
`

export const ContainerCenterInfo = styled(ContainerColum)`
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
}`

export const Title = styled(LabelTitleHeadline1)`
  margin-top: 24px;
  text-align: center;
`

export const Description = styled(Label)`
  text-align: center;
  width: 100%;
`
