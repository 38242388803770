import React from 'react'

import { HashRouter as Router, Routes, Route } from 'react-router-dom'

import routes from './routes'

const AppRoutes = () => (
  <Router basename="/">
    <Routes>
      {routes.map((route) => (
        <Route {...route} key={route.path[0]} />
      ))}
    </Routes>
  </Router>
)

export default AppRoutes
