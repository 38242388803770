import styled, { css } from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

export const headerStyles = css`
  position: relative;
  padding-top: 56px;
`

export const Container = styled.div`
  width: 100vw;
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: var(--neutral-theme);
  padding: 0 24px;
  top: 0;
  left: 0;
  z-index: var(--zIndexMaximum);
`

export const Title = styled(Text).attrs({
  color: 'typography',
  variant: 'headline-h3',
  semiBold: true,
})`
  align-self: center;
  margin: auto;
`

export const IconButton = styled.button`
  background: transparent;
  border: 0;
`
