import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'
import { Button as InterButton } from '@interco/inter-ui/components/Button'

export const LabelNameCompany = styled.div`
  align-self: start;
  margin: 12px 0 8px;
`

export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`

export const ButtonSecondary = styled(InterButton).attrs({ variant: 'secondary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`

export const Label = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'body-3',
  semiBold: true,
})`
  align-self: start;
  margin: 8px 0 0;
`
