import React from 'react'

import { InterOneCard } from '@/assets/images'
import { RectangleIcon } from '@/assets/icons'

import * as S from './styles'

export type HeaderProps = {
  title: string
  info: string
}

export const InterOne = ({ title, info }: HeaderProps) => (
  <S.Container>
    <S.Sector>
      <S.Title>{title}</S.Title>
      <S.CardIcon>
        <InterOneCard />
      </S.CardIcon>
    </S.Sector>
    <S.Sector>
      <S.RectangleIcon>
        <RectangleIcon />
      </S.RectangleIcon>
      <S.Info>{info}</S.Info>
    </S.Sector>
  </S.Container>
)
