import React from 'react'

import Main from '@/main'
import CompanyInfo from '@/pages/CompanyInfo'
import OriginBank from '@/pages/OriginBank'
import Resume from '@/pages/Resume'
import Feedback, { FeedbackPageEnum } from '@/pages/Feedback'
import FrequentlyAskedQuestions from '@/pages/FrequentlyAskedQuestions'
import Home from '@/pages/Home'
import Onboarding from '@/pages/Onboarding'

export const RoutesNames = {
  ROOT: '/',
  ONBOARDING: '/Onboarding',
  FAQ_WITH_VIDEO: `/Onboarding:faq`, // FAQ_WITH_VIDEO: `/FAQ:video`,
  COMPANY_INFO: `/CompanyInfo:create`,
  COMPANY_INFO_EDIT: `/CompanyInfo:edit`,
  ORIGIN_BANK: `/OriginBank:create`,
  ORIGIN_BANK_EDIT: `/OriginBank:edit`,
  RESUME: '/Resume',
  FEEDBACK_SUCCESS: `/Feedback:${FeedbackPageEnum.success}`,
  FEEDBACK_ERROR_LOAD: `/Feedback:${FeedbackPageEnum.erroLoad}`,
  FEEDBACK_ERROR_REQUEST: `/Feedback:${FeedbackPageEnum.errorRequest}`,
  FEEDBACK_SENT_REQUEST: `/Feedback:${FeedbackPageEnum.errorSendRequest}`,
  FAQ_WITH_TIPS: `/FAQ:tips`,
  HOME: '/Home',
}
export default [
  { path: RoutesNames.ROOT, element: <Main /> },
  { path: `${RoutesNames.ONBOARDING}`, element: <Onboarding /> },
  { path: `${RoutesNames.COMPANY_INFO}`, element: <CompanyInfo /> },
  { path: `${RoutesNames.COMPANY_INFO_EDIT}`, element: <CompanyInfo /> },
  { path: `${RoutesNames.ORIGIN_BANK}`, element: <OriginBank /> },
  { path: `${RoutesNames.ORIGIN_BANK_EDIT}`, element: <OriginBank /> },
  { path: RoutesNames.RESUME, element: <Resume /> },
  { path: RoutesNames.FEEDBACK_SUCCESS, element: <Feedback /> },
  { path: RoutesNames.FEEDBACK_ERROR_LOAD, element: <Feedback /> },
  { path: RoutesNames.FEEDBACK_ERROR_REQUEST, element: <Feedback /> },
  { path: RoutesNames.FEEDBACK_SENT_REQUEST, element: <Feedback /> },
  { path: RoutesNames.HOME, element: <Home /> },
  {
    path: RoutesNames.FAQ_WITH_TIPS,
    element: <FrequentlyAskedQuestions />,
  },
  {
    path: RoutesNames.FAQ_WITH_VIDEO,
    element: <Onboarding />,
  },
]
