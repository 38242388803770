import { IWbUserInfo } from '@interco/inter-webview-bridge'

const userInfo: IWbUserInfo = {
  account: '12345678',
  cpf: '12345678909',
  name: 'User',
  email: 'user@bancointer.com.br',
  phoneNumber: '31999999999',
  cellPhoneNumber: '',
  profile: '',
  birthDate: 0,
  userType: 'basic_account',
  segmentation: 'DIGITAL',
}

export default userInfo
