import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'
import { Button as InterButton } from '@interco/inter-ui/components/Button'
import { ContainerColum } from '@/components/Containers'

export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: start;
  margin: 24px 0 0;
  width: 100%;
`
export const BabiMargin = styled.div`
  margin: 24px 0 0;
`
export const Title = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h1',
  semiBold: true,
})`
  align-self: start;
  margin: 24px 0 0;
`
export const SubTitle = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h2',
  semiBold: true,
})`
  align-self: start;
  margin: 24px 0 0;
`

export const Info = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'body-3',
  semiBold: true,
})`
  align-self: start;
  margin: 8px 0 0;
`
export const Container = styled.div`
  padding: 80px 0 0;
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-theme);
`
export const CustomContainerColum = styled(ContainerColum)`
  padding: 0 24px 24px;
`
