const home = {
  header: 'Portabilidade de salário',
  title: 'Status de portabilidade',
  portabilities: [
    {
      portabilityType: {
        status: 'EM_ANALISE',
        icon: {
          id: 'Attention',
        },
        color: '--alert500',
        title: 'Em análise',
        companyName: 'Banco Inter',
        companyCnpj: '00416968000101',
        bankName: 'BCO BRADESCO S.A.             ',
      },
      timeline: [
        {
          status: 'SOLICITACAO_FEITA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Solicitacao feita',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'EM_ANALISE',
          icon: {
            id: 'Attention',
          },
          color: '--alert500',
          title: 'Em analise',
          description: 'Você receberá um retorno em até 15 dias.',
          date: 'Thu Apr 28 17:10:02 GMT-03:00 2022',
        },
        {
          status: 'RESULTADO',
          icon: {
            id: 'checkcircle',
          },
          color: '--gray300',
          title: 'Resultado da solicitação',
          description: '',
        },
      ],
      portabilityNumber: {
        title: 'Número Único de Portabilidade',
        icon: {
          id: 'Portability',
        },
        copyNumberText: 'Copiar número',
        uniqueNumber: '00416968999900945444',
      },
    },
    {
      portabilityType: {
        status: 'APROVADA',
        icon: {
          id: 'checkcircle',
        },
        color: '--success500',
        title: 'Aprovada',
        companyName: 'Banco Inter',
        companyCnpj: '00416968000101',
        bankName: 'ITAU UNIBANCO S.A.            ',
      },
      timeline: [
        {
          status: 'SOLICITACAO_FEITA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Solicitacao feita',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'ANALISE_CONCLUIDA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Análise Concluída',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'SOLICITACAO_APROVADA',
          icon: {
            id: 'checkcircle',
          },
          color: '--success500',
          title: 'Solicitação aprovada',
          description: 'Você agora receberá seu salário pelo Inter!',
        },
      ],
      portabilityNumber: {
        title: 'Número Único de Portabilidade',
        icon: {
          id: 'Portability',
        },
        copyNumberText: 'Copiar número',
        uniqueNumber: '00416968999900945444',
      },
      alert: {
        id: 'INFO',
        icon: {
          id: 'info',
          color: '--neutral-theme',
          backgroundColor: '--gray500',
        },
        color: '--gray100',
        description:
          'Caso a solicitação da portabilidade tenha sido feita com menos de 15 dias de antecedência, o primeiro salário transferido será o do mês seguinte.',
      },
    },
    {
      portabilityType: {
        status: 'REPROVADA',
        icon: {
          id: 'canceled',
        },
        color: '--error500',
        title: 'Reprovada',
        companyName: 'Teste Fe',
        companyCnpj: '65124307001979',
        bankName: 'BCO SANTANDER (BRASIL) S.A.   ',
      },
      timeline: [
        {
          status: 'SOLICITACAO_FEITA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Solicitacao feita',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'ANALISE_CONCLUIDA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Análise Concluída',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'REPROVADA',
          icon: {
            id: 'Canceled',
          },
          color: '--error500',
          title: 'Solicitação reprovada',
          description: '',
          date: 'Fri Apr 08 17:11:18 GMT-03:00 2022',
        },
      ],
      portabilityNumber: {
        title: 'Número Único de Portabilidade',
        icon: {
          id: 'Portability',
        },
        copyNumberText: 'Copiar número',
        uniqueNumber: '00416968999900945444',
      },
      alert: {
        id: 'CANCELED',
        icon: {
          id: 'canceled',
          color: '--neutral-theme',
          backgroundColor: '--error500',
        },
        color: '--error100',
        description: '',
      },
      button: {
        title: 'Solicitar novamente',
        idAction: '0',
      },
    },
    {
      portabilityType: {
        status: 'REPROVADA',
        icon: {
          id: 'canceled',
        },
        color: '--error500',
        title: 'Reprovada',
        companyName: 'Teste Fe',
        companyCnpj: '65124307001979',
        bankName: 'BCO SANTANDER (BRASIL) S.A.   ',
      },
      timeline: [
        {
          status: 'SOLICITACAO_FEITA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Solicitacao feita',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'ANALISE_CONCLUIDA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Análise Concluída',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'REPROVADA',
          icon: {
            id: 'Canceled',
          },
          color: '--error500',
          title: 'Solicitação reprovada',
          description: '',
          date: 'Fri Apr 08 17:11:18 GMT-03:00 2022',
        },
      ],
      portabilityNumber: {
        title: 'Número Único de Portabilidade',
        icon: {
          id: 'Portability',
        },
        copyNumberText: 'Copiar número',
        uniqueNumber: '00416968999900945444',
      },
      alert: {
        id: 'CANCELED',
        icon: {
          id: 'canceled',
          color: '--neutral-theme',
          backgroundColor: '--error500',
        },
        color: '--error100',
        description: '',
      },
      button: {
        title: 'Solicitar novamente',
        idAction: '0',
      },
    },
    {
      portabilityType: {
        status: 'REPROVADA',
        icon: {
          id: 'canceled',
        },
        color: '--error500',
        title: 'Reprovada',
        companyName: 'Teste Fe',
        companyCnpj: '65124307001979',
        bankName: 'BCO SANTANDER (BRASIL) S.A.   ',
      },
      timeline: [
        {
          status: 'SOLICITACAO_FEITA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Solicitacao feita',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'ANALISE_CONCLUIDA',
          icon: {
            id: '',
          },
          color: '--success500',
          title: 'Análise Concluída',
          description: '',
          date: 'Fri Apr 08 16:24:46 GMT-03:00 2022',
        },
        {
          status: 'REPROVADA',
          icon: {
            id: 'Canceled',
          },
          color: '--error500',
          title: 'Solicitação reprovada',
          description: '',
          date: 'Fri Apr 08 17:11:18 GMT-03:00 2022',
        },
      ],
      portabilityNumber: {
        title: 'Número Único de Portabilidade',
        icon: {
          id: 'Portability',
        },
        copyNumberText: 'Copiar número',
        uniqueNumber: '00416968999900945444',
      },
      alert: {
        id: 'CANCELED',
        icon: {
          id: 'canceled',
          color: '--neutral-theme',
          backgroundColor: '--error500',
        },
        color: '--error100',
        description: '',
      },
      button: {
        title: 'Solicitar novamente',
        idAction: '0',
      },
    },
  ],
  button: {
    title: 'Nova solicitação',
    idAction: '1',
  },
  bottomSheet: {
    title: 'Número Único de Portabilidade',
    description:
      'Esse é o número referente a sua solicitação de portabilidade. Com ele, você consegue verificar o status da sua solicitação no banco de origem.',
    buttons: [
      {
        title: 'Entendi',
      },
    ],
  },
  homeFaq: {
    header: 'Portabilidade de salário',
    videoUrl: 'https://www.youtube.com/watch?v=bkgh09XXtS0',
  },
}

export default home
