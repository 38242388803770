import React from 'react'

import ReactPlayer from 'react-player'

import * as S from './styles'

export type VideoPlayerProps = {
  url: string
  onPlay?: () => void
}

export const VideoPlayer = ({ url, onPlay }: VideoPlayerProps) => (
  <S.Container>
    <ReactPlayer onPlay={onPlay} controls width="100%" height="100%" url={url} />
  </S.Container>
)
