import React, { useEffect, useState } from 'react'

import { useNavigate, useLocation } from 'react-router'
import { useQuery } from 'react-query'

import { Header } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { RoutesNames } from '@/routes/routes'
import { Option } from '@interco/inter-ui/components/SelectBottomSheet/types'
import { Container, ContainerColum } from '@/components/Containers'
import { Label, LinkText } from '@/components/Labels'
import { setBank, setListBanks } from '@/contexts/global-state/reducer/actions'
import { ApiService } from '@/services'
import * as ENDPOINTS from '@/common/constants/endpoints'
import { requestAnalytics } from '@/services/bridge'
import { LogEventPage } from '@/utils/tagEvent'
import { Alert, AlertType } from '@interco/inter-ui/components/Alert'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './styles'

const OriginBank = () => {
  const [{ bankText, bank, listBanks, user }, dispatch] = useGlobalState()
  const navigate = useNavigate()

  const location = useLocation()
  const isEditMode = location.pathname === RoutesNames.COMPANY_INFO_EDIT
  const [bankIndex, setBankIndex] = useState<Option | undefined>()

  useQuery<Bank[] | null>(
    ENDPOINTS.BANKS_PARTICIPANTS,
    async () => {
      const nrData: dataNewRelic = {
        isErro: false,
        account: user?.account || '',
        action: 'OBTER BANCOS PARTICIPANTES',
        status: '',
        requestData: '',
      }
      const response = await ApiService.Bank.getBankList(nrData)

      return response
    },
    {
      retry: false,
      retryDelay: 1000,

      onSuccess: (response) => {
        if (response) dispatch(setListBanks({ list: response }))
      },

      onError: () => {
        navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
      },
    },
  )

  const handleSelectValue = (currentValue: Option) => {
    setBankIndex(currentValue)
  }
  const onContinue = () => {
    if (bankIndex) {
      requestAnalytics('C_PSalario_BS_T_Botao', {
        content_clique: button.title,
        content_id: `${bankIndex.value} ${bankIndex.text}`,
        tela: header,
      })
      dispatch(
        setBank({
          bank: {
            bankCode: bankIndex.value.toString(),
            bankName: bankIndex.text.toString(),
          },
        }),
      )
    }
    if (isEditMode) {
      navigate(-1)
    } else {
      navigate(RoutesNames.RESUME, { replace: false })
    }
  }

  useEffect(() => {
    if (listBanks !== undefined) {
      setBankIndex(
        listBanks
          .filter((data) => data.bankCode.toString() === bank?.bankCode.toString())
          .map((option) => ({
            value: option.bankCode,
            text: option.bankName,
          }))[0],
      )
    }
  }, [bank, listBanks])

  useEffect(() => {
    if (bankText === undefined) {
      navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
    }
  }, [bankText, navigate])

  useEffect(() => {
    LogEventPage(`BANCO DE ORIGEM`, user?.userTestingType)
  }, [user?.userTestingType])
  if (bankText === undefined) {
    return null
  }
  const { header, title, description, placeHolder, bottomSheet, linkButton, button, alert } =
    bankText

  return (
    <>
      <Container>
        <Header title={header} onBackClick={() => navigate(-1)} />
        <ContainerColum>
          <Text variant="body-1" bold colorWeight={500}>
            {title}
          </Text>
          <Spacing mb="xxs" />
          <Label>{description}</Label>
          <S.Select>
            <S.SelectInput
              id="select"
              label={bottomSheet.title}
              placeholder={placeHolder}
              isUsingFilter
              fixedHeight="70vh"
              options={
                listBanks !== undefined
                  ? listBanks.map((option) => ({
                      value: option.bankCode,
                      text: `${option.bankCode} - ${option.bankName}`,
                    }))
                  : []
              }
              isLoading={listBanks === undefined}
              onChange={handleSelectValue}
              value={bankIndex}
            />
          </S.Select>
          {linkButton && (
            <LinkText
              onClick={() => {
                requestAnalytics('C_PSalario_T_Ajuda')
                navigate(RoutesNames.FAQ_WITH_TIPS, { replace: false })
              }}
            >
              {linkButton.title}
            </LinkText>
          )}
        </ContainerColum>
        <div>
          {alert && (
            <Alert title={alert.title} type={(alert.type as AlertType) || 'grayscale'}>
              {alert.description}
            </Alert>
          )}

          <S.Button disabled={!bankIndex} onClick={onContinue}>
            {button.title}
          </S.Button>
        </div>
      </Container>
    </>
  )
}
export default OriginBank
