import { action } from 'cdpro-redux-utils'

import { IWbUserInfo } from '@interco/inter-webview-bridge'

export const Types = {
  User: {
    SET_USER: '@user/SET_USER',
  },
  Onboarding: {
    SET_ONBOARDING_TEXT: '@onboarding/SET_ONBOARDING_TEXT',
  },
  Company: {
    SET_COMPANY: '@company/SET_COMPANY',
  },
  Bank: {
    SET_BANK: '@bank/SET_BANK',
    SET_LIST_BANKS: '@bank/SET_LIST_BANKS',
  },
  Portability: {
    SET_PORTABILIY_FORM_METADATA: '@portability/SET_PORTABILIY_FORM_METADATA',
  },
  ResetData: {
    RESET_DATA: '@app/RESET_DATA',
  },
  Feedback: {
    SET_FEEDBACK: '@feedback/SET_FEEDBACK',
  },
  Home: {
    SET_HOME: '@home/SET_HOME',
    SET_SKIP_HOME_PAGE: '@home/SET_SKIP_HOME_PAGE',
    RESET_DATA: '@home/RESET_DATA',
  },
}

export const setSkipHomePage = action<boolean>(Types.Home.SET_SKIP_HOME_PAGE)

export const setCompany = action<{ company: Company }>(Types.Company.SET_COMPANY)

export const setBank = action<{ bank: Bank }>(Types.Bank.SET_BANK)
export const setListBanks = action<{ list: Bank[] }>(Types.Bank.SET_LIST_BANKS)

export const setOboardingText = action<{ onboarding: OnboardingPage }>(
  Types.Onboarding.SET_ONBOARDING_TEXT,
)

export const setFeedback = action<{ feedback: Feedback }>(Types.Feedback.SET_FEEDBACK)

export const setHome = action<{ home: HomePage }>(Types.Home.SET_HOME)

export const setPortabilityFormMetadata = action<{ metadata: PortabilityForm }>(
  Types.Portability.SET_PORTABILIY_FORM_METADATA,
)

export const resetData = action<void>(Types.ResetData.RESET_DATA)
export const resetHomeData = action<void>(Types.Home.RESET_DATA)

export const setUser = action<{ user: IWbUserInfo & { userTestingType?: 'A' | 'B' } }>(
  Types.User.SET_USER,
)
