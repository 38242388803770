import React, { useCallback, useEffect } from 'react'

import { useLocation, useNavigate, Navigate } from 'react-router-dom'

import { RoutesNames } from '@/routes/routes'
import { BridgeService } from '@/services'
import useGlobalState from '@/contexts/global-state'
import { setUser } from '@/contexts/global-state/reducer/actions'
import { PageSkeleton } from '@/components/PageSkeletons'
import { Header } from '@/components'
import { WbSessionActions } from '@interco/inter-webview-bridge'

const Main = () => {
  const history = useNavigate()
  const location = useLocation()

  const [{ user }, dispatch] = useGlobalState()
  const onHeaderBackPress = useCallback(() => {
    if ([RoutesNames.ROOT].includes(location.pathname)) {
      BridgeService.interWbNavigate.requestGoBack()
      return
    }

    history(-1)
  }, [location, history])

  const getUserInfo = useCallback(async () => {
    if (user) return
    const response = await BridgeService.getUserInfo()

    dispatch(
      setUser({
        user: {
          ...response,
          cpf: response.cpf,
        },
      }),
    )
  }, [dispatch, user])

  useEffect(() => {
    BridgeService.iWb.addWebviewEventListener(WbSessionActions.GO_BACK, onHeaderBackPress)
  }, [onHeaderBackPress])

  const onMount = useCallback(() => {
    const callStack = async () => {
      await getUserInfo()
    }
    // Timeout used because MSW not initialized
    BridgeService.inApp ? callStack() : setTimeout(callStack, 1_000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    onMount()
  }, [onMount])

  if (user === undefined)
    return (
      <>
        <Header
          title="Portabilidade de Salário"
          onBackClick={() => BridgeService.interWbNavigate.requestGoBack()}
        />
        <PageSkeleton />
      </>
    )
  return <Navigate to={`${RoutesNames.HOME}`} state={{ showVideo: true }} />
}

export default Main
