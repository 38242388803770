import styled from 'styled-components'

import { Button as InterButton } from '@interco/inter-ui/components/Button'
import { ContainerRowSpaceBetweenMargin } from '@/components/Containers'

export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`

interface ContainerHeaderProps {
  showMarginTop?: boolean
}
export const ContainerHeader = styled(ContainerRowSpaceBetweenMargin)<ContainerHeaderProps>`
  margin-top: ${({ showMarginTop }) => (showMarginTop ? '24px !important' : '0px')};
  margin-bottom: 24px !important;
  > span {
    margin: 0px !important;
  }
`
