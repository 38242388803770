export const RequestPortability = {
  feedback: {
    id: 'TIMEOUT',
    type: 'ALERT',
    title: 'Sua solicitação está em andamento',
    description:
      'Ainda estamos processando sua solicitação de portabilidade. Por favor, volte daqui alguns instantes e confira se sua solicitação foi criada com sucesso.',
    buttons: [
      {
        title: 'Entendi',
      },
    ],
  },
}
