import React, { useState } from 'react'

import { Timeline } from '@interco/inter-ui/components/Timeline'
import Help from '@inter/icons/bidis/v2/action/help'
import { Alert } from '@interco/inter-ui/components/Alert'

import { ExpandedPanel } from '../ExpandedPanel'
import * as S from './styles'
import { ContainerColum } from '../Containers'
import {
  LinkTextCopyPaste,
  TextBody3Bold,
  TextCaption1,
  TextCaption1Bold,
  TextCaption2,
} from '../Labels'
import { Icon } from '../IconComponent'

const returnStatus = (color: string) => {
  switch (color) {
    case '--success500':
      return 'Success'
    case '--alert500':
      return 'Alert'
    case '--error500':
      return 'Error'
    default:
      return 'Disabled'
  }
}
export const returnAlertType = (color: string) => {
  switch (color) {
    case '--success100':
      return 'success'
    case '--error100':
      return 'error'
    case '--alert100':
      return 'warning'
    default:
      return 'grayscale'
  }
}

const renderHeader = ({ portabilityType }: PortabilityRequest) => (
  <S.ContainerHeader>
    <S.ContainerIcon>
      <Icon
        name={portabilityType.icon.id}
        height={27}
        width={27}
        color={`var(${portabilityType.color})`}
      />
    </S.ContainerIcon>
    <ContainerColum>
      <TextBody3Bold>{portabilityType.title}</TextBody3Bold>
      <TextCaption1>{portabilityType.companyName}</TextCaption1>
      <TextCaption1>{`${portabilityType.companyCnpj}`}</TextCaption1>
      <TextCaption1>{portabilityType.bankName}</TextCaption1>
    </ContainerColum>
  </S.ContainerHeader>
)

const renderTimeLine = ({ timeline }: PortabilityRequest) => (
  <Timeline>
    {timeline.map((data) => (
      <Timeline.Item
        title={data.title}
        icon={
          data.icon.id !== '' ? (
            <S.IconHistory>
              <Icon name={data.icon.id} height={20} width={20} color={`var(${data.color})`} />
            </S.IconHistory>
          ) : (
            false
          )
        }
        type={returnStatus(data.color)}
      >
        <>
          {data.description}
          <br />
        </>
        {data.date}
      </Timeline.Item>
    ))}
  </Timeline>
)

type CardStatusRequestProps = {
  open: boolean
  data: PortabilityRequest
  onClickPortability: () => void
  onClickButton: ({ hideLoadingButton }: { hideLoadingButton: () => void }) => void
  copyTextToClipboard: (text: string) => void
  onOpenCard: () => void
}
export const CardStatusRequest = ({
  data,
  open,
  onClickPortability,
  onClickButton,
  copyTextToClipboard,
  onOpenCard,
}: CardStatusRequestProps) => {
  const { portabilityNumber, alert, button } = data

  const [isLoading, setIsLoading] = useState(false)
  return (
    <S.Container>
      <ExpandedPanel
        onExpand={onOpenCard}
        open={open}
        header={renderHeader(data)}
        content={
          <S.PaddingContent>
            {renderTimeLine(data)}
            {portabilityNumber && (
              <S.ContainerPortabilityNumber>
                <S.ContainerRowVerticalCenter>
                  <S.PortabilityIcon color="var(--gray500)" height={20} width={20} />

                  <ContainerColum
                    onClick={() => copyTextToClipboard(portabilityNumber.uniqueNumber)}
                  >
                    <TextCaption1Bold>{portabilityNumber.title}</TextCaption1Bold>
                    <TextCaption1>{portabilityNumber.uniqueNumber}</TextCaption1>
                    <LinkTextCopyPaste>{portabilityNumber.copyNumberText}</LinkTextCopyPaste>
                  </ContainerColum>
                </S.ContainerRowVerticalCenter>
                <S.IconHelp onClick={onClickPortability}>
                  <Help />
                </S.IconHelp>
              </S.ContainerPortabilityNumber>
            )}
            {alert && (
              <S.AlertBox>
                <Alert type={returnAlertType(alert.color || '')}>{alert.description}</Alert>
              </S.AlertBox>
            )}

            {/* <S.ButtonDownload onClick={onClickDownload}>
              <TextCaption2>baixar</TextCaption2>
            </S.ButtonDownload> */}
            {button && (
              <S.ButtonDownload
                onClick={() => {
                  setIsLoading(true)
                  onClickButton({ hideLoadingButton: () => setIsLoading(false) })
                }}
                isLoading={isLoading}
              >
                <TextCaption2>{button.title}</TextCaption2>
              </S.ButtonDownload>
            )}
          </S.PaddingContent>
        }
        icon={<S.IconChevronDown color="var(--orange500)" />}
      />
    </S.Container>
  )
}
