import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`

export const ExpandedPanel = styled.div`
  overflow-y: hidden;
  transition: max-height 300ms ease-in-out 0s;
  background-color: transparent;
`

export const Icon = styled.div`
  transition: transform 300ms ease-in-out 0s;
`
