import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import { useQuery } from 'react-query'

import { Header } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { RoutesNames } from '@/routes/routes'
import {
  Container,
  ContainerBottomDashed,
  ContainerRowSpaceBetweenMargin,
  ContainerColum,
} from '@/components/Containers'
import {
  Label,
  LabelTitleHeadline3,
  TextCaption1,
  TextCaption1Bold,
  LinkText,
  TextBody3Bold,
  TextMaxWidth,
} from '@/components/Labels'
import { ApiService } from '@/services'
import {
  resetData,
  setFeedback,
  setPortabilityFormMetadata,
} from '@/contexts/global-state/reducer/actions'
import { BottomSheetConfirm } from '@/components/BottomSheetConfirm'
import { PORTABILITY_FORM } from '@/common/constants/endpoints'
import { PageSkeleton } from '@/components/PageSkeletons'
import { replaceTag } from '@/utils/replaceTags/replaceTags'
import { requestAnalytics } from '@/services/bridge'
import { LogEventPage } from '@/utils/tagEvent'

import * as S from './styles'

const Resume = () => {
  const [{ resumeText, company, bank, user }, dispatch] = useGlobalState()
  const navigate = useNavigate()

  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [dataBottomSheet, setDataBottomSheet] = useState<BottomSheet & { canProceed: boolean }>()

  const { isFetching } = useQuery<PortabilityForm | undefined>(
    PORTABILITY_FORM,
    async () => {
      const nrData: dataNewRelic = {
        isErro: false,
        account: user?.account || '',
        action: `[RESUMO PAGE] OBTER TEXTOS DAS PAGINAS INFO_EMPRESA, BANCO_ORIGEM, FAQ, RESUMO`,
        status: '',
        requestData: '',
      }
      const response = await ApiService.Portability.getGetMyPortabilityForm(nrData)

      return response
    },
    {
      enabled: resumeText === undefined,
      retry: false,
      onSuccess: (response) => {
        if (response) dispatch(setPortabilityFormMetadata({ metadata: response }))
      },

      onError: () => {
        navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
      },
    },
  )

  const sendRequest = async () => {
    try {
      if (company !== undefined && bank !== undefined) {
        setIsLoadingButton(true)

        const nrData: dataNewRelic = {
          isErro: false,
          account: user?.account || '',
          action: '',
          status: '',
          requestData: '',
        }
        await ApiService.Portability.validatePortability(
          { company, bank, user },
          { ...nrData, action: 'VALIDAR DADOS PORTABILIDADE' },
        )

        const response = await ApiService.Portability.requestPortability(
          { company, bank, user },
          { ...nrData, action: 'CRIAR SOLICITACAO PORTABILIDADE' },
        )

        setIsLoadingButton(false)
        if (response) {
          requestAnalytics('C_PSalario_Resumo_T_EnviarSolicitacao')
          dispatch(setFeedback({ feedback: response }))

          requestAnalytics('C_PSalario_EmAnalise_DC_SolicAnalise', {
            content_banc: bank.bankCode + bank.bankName,
            content_name: `${company.cnpj}-${company.name}`,
          })
          navigate(RoutesNames.FEEDBACK_SUCCESS, { replace: false })
        }
      }
    } catch (error) {
      try {
        const response = JSON.parse(JSON.parse(error as string).response as string)
        setIsLoadingButton(false)
        const respError: {
          feedback?: Feedback
          bottomSheet?: BottomSheet & { canProceed: boolean }
        } = {
          bottomSheet: {
            id: response?.id,
            title: response?.title,
            description: response?.description,
            buttons: response?.buttons,
            canProceed: response?.canProceed,
          },
        }
        if (respError.feedback) {
          dispatch(setFeedback({ feedback: respError.feedback }))
          navigate(RoutesNames.FEEDBACK_ERROR_REQUEST, { replace: false })
        } else if (respError.bottomSheet) {
          setDataBottomSheet(respError.bottomSheet)
          setShowBottomSheet(true)
        }
      } catch {
        navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
      }
    }
  }
  const onClickContinue = async () => {
    if (dataBottomSheet === undefined) return null

    requestAnalytics('C_PSalario_BS_T_Botao', {
      content_clique: dataBottomSheet?.buttons[0].title || '',
      content_status: dataBottomSheet?.description,
    })
    if (
      dataBottomSheet.canProceed &&
      company !== undefined &&
      bank !== undefined &&
      user !== undefined
    ) {
      setShowBottomSheet(false)
      setIsLoadingButton(true)

      const nrData: dataNewRelic = {
        isErro: false,
        account: user?.account || '',
        action:
          'VALIDACAO DOS DADOS RETORNOU UM AVISO E O USER PROSSEGUIU CRIAR SOLICITACAO PORTABILIDADE',
        status: '',
        requestData: '',
      }
      try {
        const response = await ApiService.Portability.requestPortability(
          { company, bank, user },
          nrData,
        )
        if (response) {
          dispatch(setFeedback({ feedback: response }))
          navigate(RoutesNames.FEEDBACK_SUCCESS, { replace: false })
        }
      } catch (error) {
        navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
      }
    } else {
      dispatch(resetData())
      navigate(RoutesNames.HOME, { replace: false })
    }
    return null
  }
  useEffect(() => {
    if (company === undefined || bank === undefined) {
      navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
    }
  }, [bank, company, navigate])

  useEffect(() => {
    LogEventPage(`RESUMO`, user?.userTestingType)
  }, [user?.userTestingType])

  if (isFetching) return <PageSkeleton />

  if (resumeText === undefined || company === undefined || bank === undefined) {
    return null
  }
  const { header, description, sections, button } = resumeText

  return (
    <Container>
      <Header title={header} onBackClick={() => navigate(-1)} />
      <ContainerColum>
        {description && <Label>{description}</Label>}

        <ContainerBottomDashed>
          <S.ContainerHeader showMarginTop={!!description}>
            <LabelTitleHeadline3>{sections[0].title}</LabelTitleHeadline3>
            <LinkText
              data-testid="editCompany"
              onClick={() => {
                requestAnalytics('C_PSalario_Revisao_T_Editar', {
                  content_clique: sections[0].title,
                })
                navigate(RoutesNames.COMPANY_INFO_EDIT, { replace: false })
              }}
            >
              {sections[0].textLink}
            </LinkText>
          </S.ContainerHeader>
          <ContainerRowSpaceBetweenMargin>
            <TextCaption1>{sections[0].labels[0]}</TextCaption1>
            <TextMaxWidth>
              <TextCaption1Bold>{company.cnpj}</TextCaption1Bold>
            </TextMaxWidth>
          </ContainerRowSpaceBetweenMargin>
          <ContainerRowSpaceBetweenMargin>
            <TextCaption1>{sections[0].labels[1]}</TextCaption1>
            <TextMaxWidth>
              <TextCaption1Bold>{company.name}</TextCaption1Bold>
            </TextMaxWidth>
          </ContainerRowSpaceBetweenMargin>
        </ContainerBottomDashed>
        <S.ContainerHeader showMarginTop>
          <LabelTitleHeadline3>{sections[1].title}</LabelTitleHeadline3>
          <LinkText
            data-testid="editBank"
            onClick={() => {
              requestAnalytics('C_PSalario_Revisao_T_Editar', {
                content_clique: sections[1].title,
              })
              navigate(RoutesNames.ORIGIN_BANK_EDIT, { replace: false })
            }}
          >
            {sections[1].textLink}
          </LinkText>
        </S.ContainerHeader>
        <ContainerRowSpaceBetweenMargin>
          <TextCaption1>{sections[1].labels[0]}</TextCaption1>
          <TextMaxWidth>
            <TextCaption1Bold>{bank.bankName}</TextCaption1Bold>
          </TextMaxWidth>
        </ContainerRowSpaceBetweenMargin>
      </ContainerColum>
      <S.Button onClick={sendRequest} isLoading={isLoadingButton}>
        {button.title}
      </S.Button>
      <BottomSheetConfirm
        title={dataBottomSheet?.title || ''}
        textContinue={dataBottomSheet?.buttons[0]?.title || ''}
        textCancel={dataBottomSheet?.buttons[1]?.title || ''}
        visible={showBottomSheet}
        onContinue={() => onClickContinue()}
        onCancel={() => {
          requestAnalytics('C_PSalario_BS_T_Botao', {
            content_clique: dataBottomSheet?.buttons[1].title || '',
            content_status: dataBottomSheet?.description || '',
          })
          setShowBottomSheet(false)
        }}
        closeModal={() => setShowBottomSheet(false)}
      >
        <Label>
          {replaceTag({
            info: dataBottomSheet?.description || '',
            tagList: [
              {
                renderComponent: (text: string) => <TextBody3Bold>{text}</TextBody3Bold>,
                tag: 'b',
              },
              {
                renderComponent: (text: string) => (
                  <LinkText
                    data-testid="editBank"
                    onClick={() => navigate(RoutesNames.FAQ_WITH_TIPS, { replace: false })}
                  >
                    {text}
                  </LinkText>
                ),
                tag: 'linkFaq',
              },
            ],
          })}
        </Label>
      </BottomSheetConfirm>
    </Container>
  )
}
export default Resume
