import styled from 'styled-components'

export const ContainerColum = styled.div`
  display: flex;
  flex-direction: column;
`
export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`
export const Container = styled(ContainerColum)`
  padding: 80px 24px 24px;
  min-height: 100%;
  background-color: var(--neutral-theme);
  justify-content: space-between;
`
export const ContainerFullHeight = styled.div`
  height: 100%;
`
export const ContainerBottomDashed = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`

export const ContainerRowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  justify-content: space-between;
`
export const ContainerRowSpaceBetweenMargin = styled(ContainerRowSpaceBetween)`
  margin-bottom: 16px;
`
