import React from 'react'

import ArrowLeft from '@inter/icons/orangeds/LG/arrow-left'
import { AppBar } from '@interco/inter-ui/components/AppBar'
import Help from '@inter/icons/orangeds/MD/help'
import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './styles'

export type HeaderProps = {
  onBackClick: () => void
  onClickInfo?: () => void
  title: string
  loaddingInfo?: boolean
}

export const Header = ({ onBackClick, title, onClickInfo, loaddingInfo }: HeaderProps) => (
  <S.Container>
    <AppBar
      leftIcon={
        <S.IconButton
          type="button"
          id="left-buttom"
          onClick={() => {
            onBackClick()
          }}
        >
          <ArrowLeft width={24} height={24} data-testid="left-icon" />
        </S.IconButton>
      }
      rightIcon={
        onClickInfo ? (
          <S.IconButton
            type="button"
            id="right-buttom"
            onClick={() => {
              onClickInfo()
            }}
          >
            <Help width={24} height={24} data-testid="right-icon" />
          </S.IconButton>
        ) : (
          loaddingInfo && <Skeleton animation="pulse" width="24px" height="24px" />
        )
      }
    >
      {title}
    </AppBar>
  </S.Container>
)
