import React from 'react'

import * as S from './styles'
import { Icon } from '../IconComponent'

const getIcon = (type: FeedbackStatusIcon) => {
  switch (type) {
    case 'PENDING':
      return (
        <S.YellowCircle data-testid="yellow-pending">
          <Icon name="Attention" height={32} width={32} color="var(--neutral-theme)" />
        </S.YellowCircle>
      )
    case 'PENDDING_GREEN':
      return (
        <S.GreenCircle data-testid="green-pending">
          <Icon name="Pending" height={32} width={32} color="var(--neutral-theme)" />
        </S.GreenCircle>
      )
    case 'ERROR':
      return (
        <S.RedCircle data-testid="red-canceled">
          <Icon name="Canceled" height={32} width={32} color="var(--neutral-theme)" />
        </S.RedCircle>
      )
    default:
      return <span data-testid="empty-icon-box" />
  }
}

type BoxWithIconAndTextProps = {
  icon: FeedbackStatusIcon
  title: string
  description: string
  subDescription?: string
}
export const BoxWithIconAndText = ({
  icon,
  title,
  description,
  subDescription,
}: BoxWithIconAndTextProps) => (
  <S.ContainerCenterInfo>
    {getIcon(icon)}
    <S.Title>{title}</S.Title>
    <S.Description>{description}</S.Description>
    {subDescription && <S.Description>{subDescription}</S.Description>}
  </S.ContainerCenterInfo>
)
