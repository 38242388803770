import React, { ReactNode } from 'react'

import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'

import * as S from './styles'
import { LabelTitleHeadline3 } from '../Labels'

export type BottomSheetConfirmProps = {
  visible: boolean
  title: string
  info?: string
  textContinue: string
  textCancel?: string
  closeModal: () => void
  onContinue: () => void
  onCancel?: () => void
  children?: ReactNode
}
export const BottomSheetConfirm = ({
  visible,
  title,
  info,
  textContinue,
  textCancel,
  closeModal,
  onContinue,
  onCancel,
  children,
}: BottomSheetConfirmProps) => {
  if (!visible) return <></>

  return (
    <BottomSheet onClose={() => closeModal()}>
      <LabelTitleHeadline3>{title}</LabelTitleHeadline3>
      <S.LabelNameCompany>
        {info && <S.Label data-testid="info">{info}</S.Label>}
        {children}
      </S.LabelNameCompany>
      <S.Button onClick={onContinue}>{textContinue}</S.Button>
      {textCancel && <S.ButtonSecondary onClick={onCancel}>{textCancel}</S.ButtonSecondary>}
    </BottomSheet>
  )
}
