import styled from 'styled-components'

import { Button as ComponentButton } from '@/components/Buttons'
import { ContainerColum, ContainerFullHeight } from '@/components/Containers'

export const ContainerWhitoutScroll = styled(ContainerFullHeight)`
  overflow: hidden;
`
export const ContainerButton = styled.div`
  padding: 16px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--neutral-theme);
  border-top: 1px solid var(--gray100);
`
export const Button = styled(ComponentButton)`
  margin: 0;
`

export const CustomContainerColum = styled(ContainerColum)`
  padding: 24px;
  margin-top: 56px;
  width: 100%;
  background-color: var(--neutral-theme);
  overflow: scroll;
  height: calc(100vh - 56px - 80px);
  overflow-x: hidden;
`
export const CustomContainerEmptyMessage = styled(ContainerColum)`
  height: calc(100vh - 220px);
  width: 100%;
  background-color: var(--neutral-theme);
`
