// import resp from './portabilityFormMetadata.json'

import { portabilityForm } from './portabilityForm'
import { RequestPortability } from './requestPortability'
import {
  ValidadePortabilityErrorCase1,
  ValidadePortabilityErrorCase2,
  ValidadePortabilityErrorCase3,
  ValidadePortabilityErrorCase4,
  ValidadePortabilityErrorCase5,
} from './validadePortabilityErrorCase'

export default {
  PortabilityFrom: portabilityForm,
  RequestPortability,

  ValidadePortabilityErrorCase1,
  ValidadePortabilityErrorCase2,
  ValidadePortabilityErrorCase3,
  ValidadePortabilityErrorCase4,
  ValidadePortabilityErrorCase5,
}
