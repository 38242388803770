import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate, useLocation } from 'react-router'
import { useQuery } from 'react-query'

import { Header } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { ApiService, BridgeService } from '@/services'
import { RoutesNames } from '@/routes/routes'
import { Label, TextCaption1, TextCaption1Bold, LinkText, TextMaxWidth } from '@/components/Labels'
import { Container, ContainerRowSpaceBetweenMargin, ContainerColum } from '@/components/Containers'
import { setCompany, setPortabilityFormMetadata } from '@/contexts/global-state/reducer/actions'
import { PORTABILITY_FORM } from '@/common/constants/endpoints'
import { CompanyInfoSkeleton } from '@/components/PageSkeletons'
import { BottomSheetConfirm } from '@/components/BottomSheetConfirm'
import { requestAnalytics } from '@/services/bridge'
import { LogEventPage } from '@/utils/tagEvent'
import { Spacing } from '@interco/inter-ui/components/Spacing'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './styles'

const CompanyInfo = () => {
  const [{ companyText, company, user, home }, dispatch] = useGlobalState()
  const navigate = useNavigate()
  const location = useLocation()
  const isEditMode = location.pathname === RoutesNames.COMPANY_INFO_EDIT
  const [companyInfo, setCompanyInfo] = useState({
    showInfo: false,
    name: '',
    fantasyName: '',
  })
  const [showLoadingInput, setShowLoadingInput] = useState(false)
  const [showError, setShowError] = useState(false)
  const [cnpjInput, setCnpjInput] = useState('')
  const [invalidCnpj, setInvalidCnpj] = useState('')
  const [showConfirmInfo, setShowConfirmInfo] = useState(false)
  const [errorBottomSheet, setErrorBottomSheet] = useState<BottomSheet>()
  const [countAttemptsSearch, setCountAttemptsSearch] = useState(0)

  const { isFetching } = useQuery<PortabilityForm | undefined>(
    PORTABILITY_FORM,
    async () => {
      const nrData: dataNewRelic = {
        isErro: false,
        account: user?.account || '',
        action: `[COMPANY PAGE] OBTER TEXTOS DAS PAGINAS INFO_EMPRESA, BANCO_ORIGEM, FAQ, RESUMO`,
        status: '',
        requestData: '',
      }
      const response = await ApiService.Portability.getGetMyPortabilityForm(nrData)

      return response
    },
    {
      enabled: companyText === undefined,
      retry: false,
      onSuccess: (response) => {
        if (response) dispatch(setPortabilityFormMetadata({ metadata: response }))
      },

      onError: () => {
        navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
      },
    },
  )

  const onContinue = () => {
    requestAnalytics('C_PSalario_BS_T_Botao', {
      content_clique: confirmBottomSheet.buttons[0].title,
      content_status: `${confirmBottomSheet.title} ${companyInfo.name}`,
    })
    dispatch(
      setCompany({
        company: {
          fantasyName: companyInfo.fantasyName,
          name: companyInfo.name,
          cnpj: cnpjInput,
        },
      }),
    )
    if (isEditMode) {
      navigate(-1)
    } else {
      navigate(RoutesNames.ORIGIN_BANK, { replace: false })
    }
  }

  const isSearchCompanyAvailable = useCallback(() => {
    const timeError = new Date(localStorage.getItem('cnpjMaximumAttemptErrorTime') as string)

    return (
      timeError.getTime() + parseInt(companyText?.maximumAttemptsTimer || '', 10) * 60000 <
      new Date().getTime()
    )
  }, [companyText?.maximumAttemptsTimer])

  const searchCompany = useCallback(
    async (cnpj: string) => {
      if (isSearchCompanyAvailable()) {
        try {
          const nrData: dataNewRelic = {
            isErro: false,
            account: user?.account || '',
            action: `OBTER DADOS EMPRESA COM CNPJ=${cnpj}`,
            status: '',
            requestData: '',
          }
          const response = await ApiService.CompanyInfo.getCompanyInfo(cnpj, nrData)

          if (response && response.nomeEmpresarial && response.nomeFantasia) {
            setCompanyInfo({
              name: response.nomeEmpresarial,
              fantasyName: response.nomeFantasia,
              showInfo: true,
            })
            setShowLoadingInput(false)
          }
        } catch (error) {
          try {
            const respError = JSON.parse(
              JSON.parse(error as string).response,
            ) as CompanyInfoResponse

            if (respError.bottomSheet !== undefined) {
              setErrorBottomSheet(respError.bottomSheet)

              setShowError(true)
            } else if (respError.error !== undefined) {
              requestAnalytics('C_PSalario_FeedBack_DC', {
                content_status: respError.error.message,
              })
              setInvalidCnpj(respError.error.message)
            }
          } catch (e) {
            navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
          }

          setShowLoadingInput(false)
        }
      }
    },
    [isSearchCompanyAvailable, navigate, user?.account],
  )

  useEffect(() => {
    LogEventPage('Dados da empresa', user?.userTestingType)
  }, [user?.userTestingType])

  useEffect(() => {
    setCnpjInput(company?.cnpj || '')
    if (company?.cnpj.replace(/\D/g, '').length === 14) {
      searchCompany(company.cnpj)
      setCompanyInfo({
        ...company,
        showInfo: true,
      })
    }
  }, [company, searchCompany])

  useEffect(() => {
    if (showLoadingInput) {
      searchCompany(cnpjInput)
    }
  }, [showLoadingInput, cnpjInput, searchCompany])

  if (isFetching) return <CompanyInfoSkeleton />

  if (companyText === undefined) {
    return null
  }
  const {
    title,
    header,
    description,
    companySocialNameDescription,
    companyFantasyNameDescription,
    linkButton,
    button,
    bottomSheet,
    placeHolder,
  } = companyText

  const confirmBottomSheet = bottomSheet

  return (
    <>
      <Container>
        <Header
          title={header}
          onBackClick={() =>
            home?.portabilities && home?.portabilities.length > 0
              ? navigate(-1)
              : BridgeService.interWbNavigate.requestGoBack()
          }
        />
        <ContainerColum>
          <Text variant="body-1" bold colorWeight={500}>
            {title}
          </Text>
          <Spacing mb="xxs" />
          <Label>{description}</Label>
          <S.InputField>
            <S.Input
              disabled={showLoadingInput}
              placeholder={placeHolder}
              value={cnpjInput}
              error={invalidCnpj !== ''}
              infoText={invalidCnpj}
              type="tel"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target) {
                  setCnpjInput(event.target.value)
                  if (event.target.value.length === 18) {
                    if (isSearchCompanyAvailable()) {
                      if (
                        companyText !== undefined &&
                        countAttemptsSearch < parseInt(companyText?.maximumAttempts, 10)
                      ) {
                        setCountAttemptsSearch(countAttemptsSearch + 1)
                        setShowLoadingInput(true)
                      } else {
                        setCountAttemptsSearch(0)
                        setShowLoadingInput(false)
                        setErrorBottomSheet(companyText?.maximumAttemptsBottomSheet)
                        setShowError(true)

                        localStorage.setItem('cnpjMaximumAttemptErrorTime', new Date().toString())
                      }
                    } else {
                      setErrorBottomSheet(companyText?.maximumAttemptsBottomSheet)
                      setShowError(true)
                    }
                  } else if (companyInfo.showInfo) {
                    setCompanyInfo({
                      showInfo: false,
                      name: '',
                      fantasyName: '',
                    })
                  } else if (invalidCnpj !== '') {
                    setInvalidCnpj('')
                  }
                }
              }}
            />
            {showLoadingInput && <S.LoadingIcon data-testid="loadingIcon" />}
          </S.InputField>
          {companyInfo.showInfo && (
            <S.ContainerBottomDashed data-testid="infoCompany">
              <ContainerRowSpaceBetweenMargin>
                <TextCaption1>{companySocialNameDescription}</TextCaption1>
                <TextMaxWidth>
                  <TextCaption1Bold>{companyInfo.name}</TextCaption1Bold>
                </TextMaxWidth>
              </ContainerRowSpaceBetweenMargin>
              <ContainerRowSpaceBetweenMargin>
                <TextCaption1>{companyFantasyNameDescription}</TextCaption1>
                <TextMaxWidth>
                  <TextCaption1Bold>{companyInfo.fantasyName}</TextCaption1Bold>
                </TextMaxWidth>
              </ContainerRowSpaceBetweenMargin>
            </S.ContainerBottomDashed>
          )}
          <LinkText
            onClick={() => {
              requestAnalytics('C_PSalario_T_Ajuda')
              navigate(RoutesNames.FAQ_WITH_TIPS, { replace: false })
            }}
          >
            {linkButton.title}
          </LinkText>
        </ContainerColum>
        <S.Button
          disabled={!companyInfo.showInfo || invalidCnpj !== ''}
          onClick={() => {
            requestAnalytics('C_PSalario_BS_T_Botao', {
              content_clique: button.title,
              content_dados: `${companyInfo.name} ${companyInfo.fantasyName}`,
              tela: header,
            })
            setShowConfirmInfo(true)
          }}
        >
          {button.title}
        </S.Button>
      </Container>

      <BottomSheetConfirm
        title={confirmBottomSheet.title}
        info={companyInfo.name}
        textContinue={confirmBottomSheet.buttons[0].title}
        textCancel={confirmBottomSheet.buttons[1].title}
        visible={showConfirmInfo && confirmBottomSheet !== undefined}
        onContinue={onContinue}
        onCancel={() => {
          requestAnalytics('C_PSalario_BS_T_Botao', {
            content_clique: confirmBottomSheet.buttons[1].title,
            content_status: `${confirmBottomSheet.title} ${companyInfo.name}`,
          })
          setShowConfirmInfo(false)
        }}
        closeModal={() => setShowConfirmInfo(false)}
      />

      {errorBottomSheet && (
        <BottomSheetConfirm
          title={errorBottomSheet.title}
          info={errorBottomSheet.description}
          textContinue={errorBottomSheet.buttons[0].title}
          textCancel={errorBottomSheet.buttons[1]?.title}
          visible={showError && errorBottomSheet !== undefined}
          onContinue={() => {
            requestAnalytics('C_PSalario_BS_T_Botao', {
              content_clique: errorBottomSheet.buttons[0].title,
              content_status: `${errorBottomSheet.title} ${errorBottomSheet.description}`,
            })
            setShowError(false)
            setCnpjInput('')
            if (!isSearchCompanyAvailable()) {
              BridgeService.interWbNavigate.requestGoBack()
            }
          }}
          onCancel={() => {
            requestAnalytics('C_PSalario_BS_T_Botao', {
              content_clique: errorBottomSheet.buttons[1].title,
              content_status: `${errorBottomSheet.title} ${errorBottomSheet.description}`,
            })
            BridgeService.interWbNavigate.requestGoBack()
          }}
          closeModal={() => null}
        />
      )}
    </>
  )
}

export default CompanyInfo
