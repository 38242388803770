import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const getHomePage = async (cpf: string, nrData: dataNewRelic) => {
  const response = await api<HomePage>(
    `${ENDPOINTS.HOME_PAGE}`,
    {
      method: 'GET',
      headers: { cpf },
    },
    nrData,
  )
  if (!response) {
    return undefined
  }
  return response
}
