import React from 'react-dom'

import { Header } from '@/components'
import { BridgeService } from '@/services'
import { ButtonSkeleton, CardSkeleton, TitleSkeleton } from '@/components/PageSkeletons'

import * as S from '../styles'

export const HomeSkeleton = () => (
  <S.ContainerWhitoutScroll>
    <Header
      title="Portabilidade de Salário"
      onBackClick={() => BridgeService.interWbNavigate.requestGoBack()}
      loaddingInfo
    />
    <S.CustomContainerColum>
      <TitleSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </S.CustomContainerColum>
    <S.ContainerButton>
      <ButtonSkeleton />
    </S.ContainerButton>
  </S.ContainerWhitoutScroll>
)
