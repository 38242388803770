import React, { useEffect } from 'react'

import { useNavigate, useLocation } from 'react-router'

import { BoxWithButtons } from '@/components/BoxWithButtons'
import useGlobalState from '@/contexts/global-state'
import { RoutesNames } from '@/routes/routes'
import { BridgeService } from '@/services'
import { resetData, resetHomeData } from '@/contexts/global-state/reducer/actions'
import { requestAnalytics } from '@/services/bridge'
import { LogEventPage } from '@/utils/tagEvent'

import * as S from './styles'

export enum FeedbackPageEnum {
  success = 'REQUEST_SENDED',
  erroLoad = 'ERROR_LOAD',
  errorRequest = 'ERROR_REQUEST',
  errorSendRequest = 'ERROR_SEND_REQUEST',
}
const Feedback = () => {
  const [{ feedback, user }, dispatch] = useGlobalState()
  const navigate = useNavigate()
  const location = useLocation()
  const renderBoxWithButtons = (
    data: Feedback,
    icon: FeedbackStatusIcon,
    actionPrimary: () => void,
    actionSecondary?: () => void,
  ) => {
    const secondaryButton =
      actionSecondary && data.buttons.length === 2
        ? {
            title: data.buttons[1].title || '',
            action: actionSecondary,
          }
        : undefined

    return (
      <BoxWithButtons
        icon={icon}
        title={data.title}
        description={data.description}
        subDescription={data.subDescription}
        primaryButton={{
          title: data.buttons[0].title,
          action: actionPrimary,
        }}
        secondaryButton={secondaryButton}
      />
    )
  }

  const renderRequest = (data: Feedback) => {
    switch (data.id) {
      case 'REQUEST_SENDED':
        return requestHasBeenSend(data)
      case 'ERROR_SEND_SOLICITATION':
        return errorSendRequest(data)
      case 'TIMEOUT':
        return requestTimeOut(data)
      default:
        return errorRequest(data)
    }
  }

  const requestHasBeenSend = (data: Feedback) =>
    renderBoxWithButtons(
      data,
      'PENDDING_GREEN',
      () => {
        requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: data.buttons[0].title })
        BridgeService.interWbSession.shareContent(data?.shareLink || '')
      },
      () => {
        requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: data.buttons[1].title })
        dispatch(resetData())
        dispatch(resetHomeData())
        navigate(RoutesNames.HOME, { replace: true })
      },
    )
  const requestTimeOut = (data: Feedback) =>
    renderBoxWithButtons(data, 'PENDING', () => {
      dispatch(resetData())
      dispatch(resetHomeData())
      navigate(RoutesNames.HOME, { replace: true })
    })
  const errorSendRequest = (data: Feedback) =>
    renderBoxWithButtons(
      data,
      'PENDING',
      () => {
        requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: data.buttons[0].title })
        dispatch(resetData())
        navigate(RoutesNames.ROOT, { replace: true })
      },
      () => {
        requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: data.buttons[1].title })
        dispatch(resetData())
        navigate(RoutesNames.ROOT, { replace: true })
      },
    )
  const errorRequest = (data: Feedback) =>
    renderBoxWithButtons(
      data,
      'ERROR',
      () => {
        requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: data.buttons[0].title })
        navigate(-1)
      },
      () => {
        requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: data.buttons[1].title })
        dispatch(resetData())
        navigate(RoutesNames.ROOT, { replace: true })
      },
    )

  const errorLoad = () => (
    <BoxWithButtons
      icon="ERROR"
      title="Houve um erro por aqui"
      description="Você pode tentar novamente mais tarde."
      primaryButton={{
        title: 'Tentar novamente',
        action: () => {
          requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: 'Tentar novamente' })
          navigate(RoutesNames.ROOT, { replace: true })
        },
      }}
      secondaryButton={{
        title: 'Voltar',
        action: () => {
          requestAnalytics('C_PSalario_EmAnalise_T_Botao', { content_clique: 'Voltar' })
          BridgeService.interWbNavigate.requestGoBack()
        },
      }}
    />
  )
  useEffect(() => {
    LogEventPage(location.pathname, user?.userTestingType)
  }, [location.pathname, user?.userTestingType])
  return (
    <S.FeedBackContainer>
      {feedback && RoutesNames.FEEDBACK_ERROR_LOAD !== location.pathname
        ? renderRequest(feedback)
        : errorLoad()}
    </S.FeedBackContainer>
  )
}

export default Feedback
