import styled from 'styled-components'

import { Container, ContainerColum } from '@/components/Containers'
import { LabelTitleHeadline2SemiBold, LinkText } from '@/components/Labels'
import { Button as InterButton } from '@interco/inter-ui/components/Button'

export const ContainerWhitoutPadding = styled(Container)`
  padding: 56px 24px 24px;
  border-top: solid 8px var(--gray100);
`
export const ContainerFaqs = styled(ContainerColum)`
  padding: 32px 24px 0px;
  border-top: solid 8px var(--gray100);
  min-height: 400px;
`
export const LabelTitle = styled(LabelTitleHeadline2SemiBold)`
  margin: 0 0 24px;
`

export const LinkButton = styled(LinkText)`
  width: 100%;
  margin: 0;
  padding: 16px;
  align-self: center;
  display: flex;
  justify-content: center;
`

export const TipsMargin = styled.div`
  margin: 0px 0px 50px;
`
export const VideoMargin = styled.div`
  margin: 0px 0px 32px;
`
export const ContainerMargin = styled.div`
  margin: 14px 0px 0px;
`
export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`
