const ValidadePortabilityErrorCase1 = {
  feedback: {
    id: 'PENDING',
    canProceed: false,
    title: 'Não foi possível enviar sua solicitação',
    description:
      'Para solicitar sua portabilidade, procure uma agência do banco no qual você deseja receber seu salário.',
    buttons: [{ title: 'Entendi' }],
  },
}
const ValidadePortabilityErrorCase2 = {
  id: 'REASON_REFUSAL_PENDING_REQUEST_AGAIN',
  canProceed: true,
  description:
    'Você já solicitou uma portabilidade de salário com esses mesmos dados e ela foi cancelada, porque <b>você desistiu da solicitação</b>. Se tiver interesse, solicite novamente a portabilidade.',
  buttons: [
    {
      title: 'Continuar',
    },
    {
      title: 'Solicitar novamente',
    },
  ],
  idReasonRefusal: [5],
  idReasonRefusalHML: [5],
}

const ValidadePortabilityErrorCase3 = {
  bottomSheet: {
    id: 'CONTINUA',
    canProceed: false,
    title: 'Verifique os dados informados',
    description:
      'Você já solicitou uma portabilidade de salário com esses mesmos dados e ela foi negada.\n\nPor favor, verifique se os dados informados estão corretos e válidos para a portabilidade. ',
    buttons: [
      {
        title: 'Continuar',
      },
      {
        title: 'Editar solicitação',
      },
    ],
  },
}
const ValidadePortabilityErrorCase4 = {
  bottomSheet: {
    id: 'FAQ',
    canProceed: false,
    title: 'Dados repetidos',
    description:
      'Você teve uma portabilidade aprovada em xx/xx/xxxx com esses mesmos dados. Para criar uma nova solicitação, os dados devem ser diferentes.\n\nEm caso de dúvidas, acesse nossa Central de Ajuda.',
    buttons: [
      {
        title: 'Central de Ajuda',
      },
      {
        title: 'Editar solicitação',
      },
    ],
  },
}
const ValidadePortabilityErrorCase5 = {
  bottomSheet: {
    id: 'FAQ',
    canProceed: false,
    title: 'Dados repetidos',
    description:
      'Você teve uma portabilidade aprovada em xx/xx/xxxx com esses mesmos dados. Para criar uma nova solicitação, os dados devem ser diferentes.\n\nEm caso de dúvidas, acesse nossa Central de Ajuda.',
    buttons: [
      {
        title: 'Central de Ajuda',
      },
      {
        title: 'Editar solicitação',
      },
    ],
  },
}
export {
  ValidadePortabilityErrorCase1,
  ValidadePortabilityErrorCase2,
  ValidadePortabilityErrorCase3,
  ValidadePortabilityErrorCase4,
  ValidadePortabilityErrorCase5,
}
