import React, { ReactNode } from 'react'

import { replaceTag } from '@/utils/replaceTags/replaceTags'

import * as S from './styles'

export type TopicProps = {
  icon?: ReactNode
  title: string
  info: string
  link?: { text: string; onClick: () => void }
  showBorderBottom?: boolean
}
export const TopcCard = ({ icon, title, info, link, showBorderBottom }: TopicProps) => (
  <S.Topc data-testid="topcs" showBorderBottom={showBorderBottom}>
    {icon && <S.TopcIcon>{icon}</S.TopcIcon>}
    <S.TopcInfo>
      <S.TopcTitle>{title}</S.TopcTitle>
      <S.Info>
        {replaceTag({
          info,
          tagList: [
            { renderComponent: (text: string) => <S.InfoBold>{text}</S.InfoBold>, tag: 'b' },
          ],
        })}
      </S.Info>
      {link && (
        <S.Link data-testid="link" onClick={link.onClick}>
          {link.text}
        </S.Link>
      )}
    </S.TopcInfo>
  </S.Topc>
)
