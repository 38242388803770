import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;

  width: 100%;
  height: 198px;
  margin-top: 40px;

  background: var(--neutral-theme);
  /* Grayscale / 200 */

  border: 1px solid var(--gray200);
  border-radius: 8px;
`

export const Sector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CardIcon = styled.div`
  width: 48px;
  height: 76px;
  margin-left: 12px;
`

export const RectangleIcon = styled.div`
  width: 4px;
  height: 24px;
  margin-right: 16px;
`

export const Title = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h2',
  semiBold: true,
})`
  align-self: center;
  margin: 0px 0 0;
`

export const Info = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'caption-1',
  semiBold: true,
})`
  align-self: start;
  margin: 16px 0 0;
`
