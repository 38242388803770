import styled, { keyframes } from 'styled-components'

import { Input as InterInput, COMMON_MASKS } from '@interco/inter-ui/components/Input'
import { Button as InterButton } from '@interco/inter-ui/components/Button'
import Loading from '@inter/icons/orangeds/SM/loading'
import { ContainerBottomDashed as BaseContainerBottomDashed } from '@/components/Containers'

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export const LoadingIcon = styled(Loading).attrs({})`
  align-self: end;
  width: 28px;
  margin-top: 24px;
  position: absolute;
  right: 28px;
  top: 16px;
  animation: ${rotate} 1.1s infinite linear;
`

export const Input = styled(InterInput).attrs({
  mask: COMMON_MASKS.CNPJ,
})`
  align-self: start;
  width: 100%;
  margin-top: 24px;
`

export const InputField = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
`

export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`

export const LabelNameCompany = styled.div`
  align-self: start;
  margin: 12px 0 8px;
`
export const ContainerBottomDashed = styled(BaseContainerBottomDashed)`
  margin-bottom: 40px;
`
