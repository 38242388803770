import styled from 'styled-components'

import { Skeleton as UISkeleton } from '@interco/inter-ui/components/Skeleton'

import { ContainerColum, ContainerFullHeight } from '../Containers'

export const SkeletonVideo = styled(UISkeleton).attrs({
  animation: 'pulse',
  width: '100%',
  height: '56.25vw',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--neutral-theme);
  padding: 0;
  top: 0;
  left: 0;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding: 24px;
  background-color: var(--neutral-theme);
`

export const ContainerWhitoutScroll = styled(ContainerFullHeight)`
  overflow: hidden;
`
export const ContainerButton = styled.div`
  padding: 16px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top: 1px solid var(--gray100);
  background-color: var(--neutral-theme);
`

export const CustomContainerColum = styled(ContainerColum)`
  padding: 24px;
  margin-top: 56px;
  width: 100%;
  background-color: var(--neutral-theme);
  overflow: scroll;
  height: calc(100vh - 56px - 80px);
`
