import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const getBankList = async (nrData: dataNewRelic) => {
  const response = await api<Bank[]>(
    `${ENDPOINTS.BANKS_PARTICIPANTS}`,
    {
      method: 'GET',
    },
    nrData,
  )
  if (!response) {
    return null
  }

  return response.response
}
