import React, { useState } from 'react'

import { useNavigate } from 'react-router'
import { useQuery } from 'react-query'

import { Header } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { RoutesNames } from '@/routes/routes'
import { ContainerFullHeight } from '@/components/Containers'
import { ApiService, BridgeService } from '@/services'
import { CardStatusRequest, returnAlertType } from '@/components/CardStatusRequest'
import {
  resetData,
  setBank,
  setCompany,
  setHome,
  setSkipHomePage,
  setUser,
} from '@/contexts/global-state/reducer/actions'
import { copyTextToClipboard, requestAnalytics } from '@/services/bridge'
import { BottomSheetConfirm } from '@/components/BottomSheetConfirm'
import * as ENDPOINTS from '@/common/constants/endpoints'
import { BoxWithIconAndText } from '@/components/BoxWithIconAndText'
import { LogEventPage } from '@/utils/tagEvent'
import { Text } from '@interco/inter-ui/components/Text'
import { Spacing } from '@interco/inter-ui/components/Spacing'

import * as S from './styles'
import { HomeSkeleton } from './components'

const Home = () => {
  const [{ home, user, skipHomePage }, dispatch] = useGlobalState()
  const navigate = useNavigate()

  const [portabilityBottomSheet, setPortabilityBottomSheet] = useState(false)

  const { isFetching } = useQuery<RequestResponse<HomePage> | undefined>(
    ENDPOINTS.HOME_PAGE,
    async () => {
      const nrData: dataNewRelic = {
        isErro: false,
        account: user?.account || '',
        action: 'OBTER TEXTOS HOME E LISTA DAS PORTABILIDADES',
        status: '',
        requestData: '',
      }
      const response = await ApiService.Home.getHomePage(user?.cpf || '', nrData)

      return response
    },
    {
      enabled: home === undefined,
      retry: false,
      retryDelay: 1000,

      onSuccess: (resp) => {
        if (resp !== undefined) {
          const { response, headers } = resp
          if (response) dispatch(setHome({ home: response }))
          if (user) {
            const userTestingType =
              headers !== undefined && headers['x-inter-testing-type']
                ? (headers['x-inter-testing-type'] as 'A' | 'B')
                : 'A'
            dispatch(
              setUser({
                user: { ...user, userTestingType },
              }),
            )
          }
          if (skipHomePage && response.portabilities.length === 0) {
            navigate(RoutesNames.COMPANY_INFO, { replace: true })

            dispatch(setSkipHomePage(false))
          } else {
            LogEventPage(
              `HOME`,
              headers !== undefined ? (headers['x-inter-testing-type'] as 'A' | 'B') : 'A',
            )
          }
        }
      },

      onError: () => {
        navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
      },
    },
  )

  const onClickCardButton = async ({
    data,
    hideLoadingButton,
  }: {
    data: PortabilityRequest
    hideLoadingButton: () => void
  }) => {
    if (data.button.idAction === '0') {
      const nrData: dataNewRelic = {
        isErro: false,
        account: user?.account || '',
        action: `[HOME PAGE] OBTER DADOS EMPRESA COM CNPJ=${data.portabilityType.companyCnpj}`,
        status: '',
        requestData: '',
      }
      const response = await ApiService.CompanyInfo.getCompanyInfo(
        data.portabilityType.companyCnpj,
        nrData,
      )

      if (response && response.nomeEmpresarial && response.nomeFantasia) {
        dispatch(
          setCompany({
            company: {
              fantasyName: response.nomeFantasia,
              name: response.nomeEmpresarial,
              cnpj: data.portabilityType.companyCnpj,
            },
          }),
        )
      }

      dispatch(
        setBank({
          bank: {
            bankCode: data.portabilityType.bankCode,
            bankName: data.portabilityType.bankName,
          },
        }),
      )
      hideLoadingButton()

      requestAnalytics('C_PSalario_Home_T_SolicitarDenovo')
      navigate(RoutesNames.RESUME, { replace: false })
    }
    hideLoadingButton()
    // downloadFile('https://corenpr.gov.br/portal/images/stories/testepdf2.pdf')
  }

  if (isFetching) return <HomeSkeleton />
  if (home === undefined) {
    return null
  }
  const { header, title, portabilities, button, bottomSheet, emptyPortabilitiesMessage } = home

  const openFirstCard =
    portabilities[0] &&
    portabilities[0].portabilityType.status === 'EM_ANALISE' &&
    portabilities.filter(
      (item, index) => item.portabilityType.status === 'EM_ANALISE' && index !== 0,
    ).length === 0
  return (
    <S.ContainerWhitoutScroll>
      <Header
        title={header}
        onBackClick={() => BridgeService.interWbNavigate.requestGoBack()}
        onClickInfo={() => {
          requestAnalytics('C_PSalario_T_Duvidas', { tela: 'Home' })
          navigate(RoutesNames.FAQ_WITH_VIDEO, { replace: false })
        }}
      />
      <S.CustomContainerColum>
        <Text variant="body-2" bold colorWeight={500}>
          {title}
        </Text>
        <Spacing mb="sm" />
        {portabilities.length === 0 && (
          <ContainerFullHeight>
            <S.CustomContainerEmptyMessage>
              <BoxWithIconAndText
                icon="PENDING"
                title={emptyPortabilitiesMessage || ''}
                description=""
              />
            </S.CustomContainerEmptyMessage>
          </ContainerFullHeight>
        )}
        {portabilities.map((data: PortabilityRequest, index) => (
          <CardStatusRequest
            onOpenCard={() => {
              requestAnalytics('C_PSalario_Home_T_Card', {
                content_clique: `${data.portabilityType.companyName} CNPJ ${data.portabilityType.companyCnpj} ${data.portabilityType.bankName}`,
                content_status: data.portabilityType.status,
              })
              if (data.alert) {
                requestAnalytics(
                  returnAlertType(data.alert.color || '') === 'error'
                    ? 'Alert_DC_Erro'
                    : 'Alert_DC_Atencao',
                  {
                    // content_conta: {{tipo de conta}},
                    // content_produt: {{nome do produto}},
                    tela: 'Home',
                    content_status: data.alert.description,
                  },
                )
              }
            }}
            open={index === 0 ? openFirstCard : false}
            data={data}
            onClickPortability={() => {
              requestAnalytics('C_PSalario_Home_T_NumUnico')
              setPortabilityBottomSheet(true)
            }}
            onClickButton={({ hideLoadingButton }) =>
              onClickCardButton({ data, hideLoadingButton })
            }
            copyTextToClipboard={(text: string) => {
              copyTextToClipboard(text, 'Copiado')
            }}
          />
        ))}
      </S.CustomContainerColum>
      <S.ContainerButton>
        <S.Button
          onClick={() => {
            requestAnalytics('C_PSalario_Home_T_NovaSolicitacao')
            dispatch(resetData())
            navigate(RoutesNames.COMPANY_INFO, { replace: false })
          }}
        >
          {button.title}
        </S.Button>
      </S.ContainerButton>

      <BottomSheetConfirm
        title={bottomSheet.title || ''}
        textContinue={bottomSheet.buttons[0].title || ''}
        info={bottomSheet.description}
        visible={portabilityBottomSheet}
        onContinue={() => setPortabilityBottomSheet(false)}
        closeModal={() => setPortabilityBottomSheet(false)}
      />
    </S.ContainerWhitoutScroll>
  )
}
export default Home
