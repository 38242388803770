import React, { useCallback, useEffect } from 'react'

import ReactDOM from 'react-dom/client'

import { IS_DEVELOPMENT } from '@/common/constants'
import { enableDarkMode, initTheme, Theme } from '@interco/inter-ui'
import { TrackData } from '@inter/track'
import { interWbSession } from '@interco/inter-webview-bridge'

import AppProviders from './contexts'
import Routes from './routes'
import './styles/global.scss'
import { inApp } from './services/bridge'

if (IS_DEVELOPMENT) {
  const { worker } = require('@/common/mocks/handlers')

  worker().start()
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const App = () => {
  const getAppInfo = useCallback(async () => {
    const response = await interWbSession.getAppInfo()

    if (JSON.parse(JSON.stringify(response)).isDarkMode === true) {
      enableDarkMode()
    }
  }, [])
  useEffect(() => {
    initTheme(Theme.PF)
    if (inApp) getAppInfo()
  }, [getAppInfo])
  TrackData.initOrUpdate({
    runtimeEnv: (process.env.REACT_APP_NODE_ENV as EnvType) || '',
    projectName: 'WEBVIEW-PORTABILIDADE-SALARIO',
  })
  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  )
}
root.render(<App />)
