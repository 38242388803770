import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

import { LinkText } from '../Labels'

interface TopcProps {
  showBorderBottom?: boolean
}
export const Topc = styled.div<TopcProps>`
  padding: 24px 0 16px;
  display: flex;
  flex-direction: row;
  ${({ showBorderBottom }) => (showBorderBottom ? 'border-bottom: 1px solid var(--gray200);' : '')}
`
export const TopcInfo = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
`

export const Link = styled(LinkText)`
  align-self: start;
`

export const TopcIcon = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 40px;
`

export const TopcTitle = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'body-3',
  bold: true,
})`
  align-self: start;
  margin: 0;
`
export const Info = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'body-3',
  semiBold: false,
})`
  align-self: start;
  margin: 8px 0 0;
`

export const InfoBold = styled(Text).attrs({
  color: 'typography',
  colorWeight: 700,
  variant: 'body-3',
  bold: true,
})`
  align-self: start;
  margin: 8px 0 0;
`
