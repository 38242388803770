/* istanbul ignore file */
import { IS_DEVELOPMENT, IS_TEST } from '@/common/constants'
import * as BridgeService from '@/services/bridge'

import { FetchOptions } from './types'

export async function api<T = Record<string, unknown>>(
  url: string,
  options: FetchOptions['options'],
  nrData: dataNewRelic,
): Promise<RequestResponse<T> | undefined> {
  const endpoint = `app-pf/portabilidade/v1/${url}`
  try {
    const response = await BridgeService.request<T>({
      endpoint,
      nrData,
      body: options.body,
      headers: {
        ...(options.headers || {}),
        'Content-Type': 'application/json',
      },
      method: options.method,
    })

    if (response && (response as FetchError).errors) {
      throw new Error(
        JSON.stringify({
          error: (response as FetchError).errors,
          endpoint,
        }),
      )
    }

    return response
  } catch (error) {
    if (
      (IS_DEVELOPMENT && !BridgeService.inApp) ||
      (IS_TEST && !BridgeService.inApp) ||
      /* TODO: next condition should be removed after testing */ !BridgeService.inApp
    ) {
      try {
        const { body } = options
        const data = await fetch(`${window.location.origin}/${url}`, {
          method: options.method,
          body: options.method === 'POST' ? JSON.stringify({ cnpj: body?.cnpj }) : undefined,
          headers: {
            ...(options.headers || {}),
            'Content-Type': 'Application/JSON',
          },
        })
        const json = await data.json()
        if (data.status !== 200) {
          return Promise.reject(json)
        }
        return Promise.resolve(json)
      } catch (e) {
        return Promise.reject(e)
      }
    }
    return Promise.reject(error)
  }
}
