import Card from '@inter/icons/orangeds/LG/card'
import Investments from '@inter/icons/orangeds/LD/investments'
import Cashback from '@inter/icons/orangeds/XL/cashback'
import Attention from '@inter/icons/orangeds/SM/attention'
import CheckCircle from '@inter/icons/orangeds/SM/check-circle'
import Canceled from '@inter/icons/orangeds/SM/canceled'
import DepositByBoleto from '@inter/icons/orangeds/MD/deposit-by-boleto'
import CityHall from '@inter/icons/orangeds/MD/city-hall'
import Receipt from '@inter/icons/orangeds/MD/receipt'
import PaymentNotification from '@inter/icons/orangeds/SM/payment-notification'
import ListCheck from '@inter/icons/orangeds/MD/list-check'
import Building from '@inter/icons/orangeds/MD/building'
import Pending from '@inter/icons/orangeds/LG/pending'
import Download from '@inter/icons/orangeds/XL/download'

type IconProps = { color?: string; name: string; width?: number; height?: number }

export const Icon = (props: IconProps) => {
  const { name } = props
  switch (name.toLowerCase().replace(/[-]/g, '')) {
    case 'card':
      return <Card {...props} />
    case 'investments':
      return <Investments {...props} />
    case 'cashback':
      return <Cashback {...props} />
    case 'attention':
      return <Attention {...props} />
    case 'checkcircle':
      return <CheckCircle {...props} />
    case 'canceled':
      return <Canceled {...props} />
    case 'depositbyboleto':
      return <DepositByBoleto {...props} />
    case 'cityhall':
      return <CityHall {...props} />
    case 'building':
      return <Building {...props} />
    case 'receipt':
      return <Receipt {...props} />
    case 'paymentnotification':
      return <PaymentNotification {...props} />
    case 'listcheck':
      return <ListCheck {...props} />
    case 'pending':
      return <Pending {...props} />
    case 'download':
      return <Download {...props} />

    default:
      return null
  }
}
