import { requestAnalytics } from '@/services/bridge'

export const LogEventPage = (screenName: string, userTestingType?: 'A' | 'B') => {
  requestAnalytics('PSalario_Acesso', {
    screen: screenName,
    content_action: 'Dado carregado',
    profile: '0',
    product: 'Portabilidade de Salario',
    testing_type: userTestingType as string,
  })
}
