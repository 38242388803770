import styled from 'styled-components'

import ChevronDown from '@inter/icons/orangeds/MD/chevron-down'
import Portability from '@inter/icons/orangeds/MD/portability'
import { ButtonSecondary } from '@/components/Buttons'

import { ContainerRow, ContainerRowSpaceBetween } from '../Containers'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid var(--gray200);
  border-radius: 8px;
  margin: 0 0 16px 0;
`
export const PaddingContent = styled.div`
  padding: 16px;
  border-top: 1px dashed var(--gray200);
`
export const ContainerIcon = styled.div`
  margin: 0 16px 0 0;
  display: flex;
  align-items: center;
`

export const ContainerHeader = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  background-color: transparent;
`

export const IconChevronDown = styled(ChevronDown)`
  margin: 0 16px 0 0;
`

export const ButtonDownload = styled(ButtonSecondary)`
  padding: 8px;
  margin: 16px 0 0;
`
export const IconHistory = styled.div`
  background-color: var(--neutral-theme);
  margin: 0 0 0 0;
`

export const ContainerPortabilityNumber = styled(ContainerRowSpaceBetween)`
  border: 1px solid var(--gray200);
  border-radius: 8px;
`

export const PortabilityIcon = styled(Portability)`
  margin: 0 18px 0 0;
`

export const ContainerRowVerticalCenter = styled(ContainerRow)`
  align-items: center;
  padding: 8px 0px 8px 14px;
`

export const IconHelp = styled.div`
  align-items: center;
  padding: 8px 14px;
`

export const AlertBox = styled.div`
  padding: 14px 0 0;
`
