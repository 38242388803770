import styled from 'styled-components'

import { Button as InterButton } from '@interco/inter-ui/components/Button'
// import { Text } from '@interco/inter-ui/components/Text'
import { Container } from '@/components/Containers'

export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`

export const FeedBackContainer = styled(Container)`
  justify-content: end;

  height: 100%;
`
