import React, { useEffect, useState } from 'react'

import { useNavigate, useLocation } from 'react-router'

import { Header } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { RoutesNames } from '@/routes/routes'
import { VideoPlayer } from '@/components/VideoPlayer'
import { TopcCard } from '@/components/TopcCard'
import { BottomSheetConfirm } from '@/components/BottomSheetConfirm'
import { Icon } from '@/components/IconComponent'
import { requestAnalytics } from '@/services/bridge'
import { LogEventPage } from '@/utils/tagEvent'
import { Title } from '@/components/Labels'
import { Spacing } from '@interco/inter-ui/components/Spacing'

import * as S from './styles'

const renderHomeFaq = ({ header, videoUrl }: HomeFaq, goBack: () => void) => [
  <Header title={header} onBackClick={goBack} />,
  <S.VideoMargin>
    <VideoPlayer url={videoUrl} />
  </S.VideoMargin>,
]

const renderTips = (
  data: PortabilityTips,
  onClickLink: (bottomSheet?: BottomSheetTips) => void,
  goBack: () => void,
) => [
  <Header title={data.header} onBackClick={goBack} />,
  <Spacing mb="xxs" />,
  <Title>{data.title}</Title>,
  <S.TipsMargin>
    {data.tips.map((item, index) => (
      <TopcCard
        showBorderBottom={index + 1 < data.tips.length}
        icon={
          item.icon ? (
            <Icon name={item.icon.id} color={`var(${item.icon.color})`} height={20} width={20} />
          ) : undefined
        }
        title={item.title}
        info={item.description}
        link={
          item.linkText !== undefined
            ? {
                text: item.linkText,
                onClick: () => {
                  requestAnalytics('C_PSalario_Dicas_T_Saiba mais', {
                    tela: data.header,
                  })
                  onClickLink(item.bottomSheet)
                },
              }
            : undefined
        }
      />
    ))}
  </S.TipsMargin>,
]

const renderBottomSheetTips = (data: Tip[]) =>
  data.map((tip) => (
    <TopcCard
      icon={
        tip.icon ? (
          <Icon name={tip.icon.id} color={`var(${tip.icon.color})`} height={20} width={20} />
        ) : undefined
      }
      title={tip.title}
      info={tip.description}
    />
  ))

const FrequentlyAskedQuestions = () => {
  const [{ faq, user }] = useGlobalState()
  const navigate = useNavigate()

  const location = useLocation()
  const isFaqWithVideo = location.pathname === RoutesNames.FAQ_WITH_VIDEO
  const isFaqWithTips = location.pathname === RoutesNames.FAQ_WITH_TIPS

  const [showBottomSheet, setShowBottomSheet] = useState(false)
  const [dataBottomSheet, setDataBottomSheet] = useState<BottomSheetTips>()

  useEffect(() => {
    LogEventPage(`FAQ_PAGE`, user?.userTestingType)
  }, [user?.userTestingType])

  if (faq === undefined) return <div />

  const { homeFaq, portabilityTips } = faq

  return (
    <S.ContainerWhitoutPadding>
      {isFaqWithVideo && homeFaq !== undefined && renderHomeFaq(homeFaq, () => navigate(-1))}

      {isFaqWithTips && portabilityTips !== undefined && (
        <div>
          {renderTips(
            portabilityTips,
            (tips?: BottomSheetTips) => {
              setDataBottomSheet(tips)
              setShowBottomSheet(true)
            },
            () => navigate(-1),
          )}
        </div>
      )}

      {portabilityTips?.button && (
        <S.Button onClick={() => navigate(-1)}>{portabilityTips?.button.title}</S.Button>
      )}
      <BottomSheetConfirm
        title={dataBottomSheet?.title || ''}
        textContinue={dataBottomSheet?.button.title || ''}
        visible={showBottomSheet}
        onContinue={() => setShowBottomSheet(false)}
        closeModal={() => setShowBottomSheet(false)}
      >
        {dataBottomSheet?.bottomSheetTips &&
          renderBottomSheetTips(dataBottomSheet?.bottomSheetTips)}
      </BottomSheetConfirm>
    </S.ContainerWhitoutPadding>
  )
}
export default FrequentlyAskedQuestions
