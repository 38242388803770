import { rest, setupWorker } from 'msw'

import {
  BANKS_PARTICIPANTS,
  COMPANY_INFO,
  HOME_PAGE,
  ONBOARDING_PAGE,
  PORTABILITY_FORM,
  REQUEST_PORTABILITY,
  VALIDATE_PORTABILITY,
} from '@/common/constants/endpoints'
import * as MOCKS from '@/common/mocks/responses'

const DEFAULT_DELAY = 1500
const SUCCESS_STATUS = 200
const ERROR_STATUS = 403

export const EndPoints = [
  rest.get(`/${HOME_PAGE}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: MOCKS.Home }),
    ),
  ),
  rest.post(`/${REQUEST_PORTABILITY}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: MOCKS.Portability.RequestPortability }),
    ),
  ),
  rest.get(`/${ONBOARDING_PAGE}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: MOCKS.Onboarding }),
    ),
  ),
  rest.get(`/${PORTABILITY_FORM}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: MOCKS.Portability.PortabilityFrom }),
    ),
  ),
  rest.get(`/${COMPANY_INFO}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: MOCKS.Company.CompanyInfo }),
    ),
  ),
  rest.get(`/${BANKS_PARTICIPANTS}`, (_request, response, context) =>
    response(
      context.delay(DEFAULT_DELAY),
      context.status(SUCCESS_STATUS),
      context.json({ response: MOCKS.Bank.listBanks }),
    ),
  ),
  rest.post(`/${VALIDATE_PORTABILITY}`, (_request, response, context) => {
    const { body } = _request

    if ((body?.toString().match(/1/g) || []).length === 14)
      return response(
        context.delay(DEFAULT_DELAY),
        context.status(ERROR_STATUS),
        context.json({ response: MOCKS.Portability.ValidadePortabilityErrorCase1 }),
      )

    if ((body?.toString().match(/2/g) || []).length === 14)
      return response(
        context.delay(DEFAULT_DELAY),
        context.status(ERROR_STATUS),
        context.json({ response: MOCKS.Portability.ValidadePortabilityErrorCase2 }),
      )

    if ((body?.toString().match(/3/g) || []).length === 14)
      return response(
        context.delay(DEFAULT_DELAY),
        context.status(ERROR_STATUS),
        context.json({ response: MOCKS.Portability.ValidadePortabilityErrorCase3 }),
      )
    if ((body?.toString().match(/4/g) || []).length === 14)
      return response(
        context.delay(DEFAULT_DELAY),
        context.status(ERROR_STATUS),
        context.json({ response: MOCKS.Portability.ValidadePortabilityErrorCase4 }),
      )
    if ((body?.toString().match(/5/g) || []).length === 14)
      return response(
        context.delay(DEFAULT_DELAY),
        context.status(ERROR_STATUS),
        context.json({ response: MOCKS.Portability.ValidadePortabilityErrorCase5 }),
      )
    return response(
      context.delay(DEFAULT_DELAY),
      context.status(ERROR_STATUS),
      context.json({
        response: JSON.stringify({
          response: JSON.stringify(MOCKS.Portability.ValidadePortabilityErrorCase2),
        }),
      }),
    )
  }),
]

export const worker = () => setupWorker(...EndPoints)
