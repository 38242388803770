import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

export const Title = styled(Text).attrs({
  color: 'typography',
  colorWeight: 500,
  variant: 'headline-h3',
  semiBold: true,
})`
  align-self: start;
  margin: 8px 0 0;
`

export const Label = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'body-3',
  semiBold: true,
})`
  align-self: start;
`

export const TextCaption1 = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'caption-1',
})`
  color: var(--gray400);
`

export const TextCaption2 = styled(Text).attrs({
  color: 'typography',
  colorWeight: 700,
  bold: true,
  variant: 'caption-2',
})``
export const TextCaption1Bold = styled(Text).attrs({
  color: 'typography',
  colorWeight: 700,
  bold: true,
  variant: 'caption-1',
})`
  color: var(--gray500);
  display: flex;
`

export const LabelTitleHeadline3 = styled(Text).attrs({
  color: 'typography',
  colorWeight: 600,
  semiBold: true,
  variant: 'headline-h3',
})`
  color: var(--gray500);
`

export const LabelTitleHeadline2SemiBold = styled(Text).attrs({
  color: 'typography',
  colorWeight: 600,
  semiBold: true,
  variant: 'headline-h2',
})`
  color: var(--gray500);
`

export const LabelTitleHeadline1 = styled(Text).attrs({
  color: 'typography',
  colorWeight: 600,
  semiBold: true,
  variant: 'headline-h1',
})`
  color: var(--gray500);
`
export const TextBody3Bold = styled(Text).attrs({
  color: 'typography',
  colorWeight: 700,
  variant: 'body-3',
  bold: true,
})``
export const LinkText = styled(Text).attrs({
  color: 'typography',
  colorWeight: 400,
  variant: 'body-3',
  bold: true,
})`
  align-self: center;
  margin: 8px 0 0;
  color: var(--primary500);
`

export const LinkTextCopyPaste = styled(LinkText).attrs({
  color: 'typography',
  colorWeight: 700,
  variant: 'caption-1',
  bold: true,
})`
  align-self: start;
  color: var(--primary500);
`

export const TextMaxWidth = styled.div`
  max-width: 50vw;
  text-align: end;
`
