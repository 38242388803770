import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const getCompanyInfo = async (cnpj: string, nrData: dataNewRelic) => {
  const response = await api<CompanyInfoResponse>(
    `${ENDPOINTS.COMPANY_INFO}`,
    {
      method: 'GET',
      headers: { cnpj },
    },
    nrData,
  )
  if (!response) {
    return null
  }

  return { ...response.response }
}
