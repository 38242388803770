import styled from 'styled-components'

import { Button as InterButton } from '@interco/inter-ui/components/Button'

export const Button = styled(InterButton).attrs({ variant: 'primary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`

export const ButtonSecondary = styled(InterButton).attrs({ variant: 'secondary' })`
  align-self: end;
  margin: 24px 0 0;
  width: 100%;
`
