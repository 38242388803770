import React from 'react-dom'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './styles'
import { Header } from '../Header'

const textSkeleton = () => (
  <>
    <Skeleton
      animation="pulse"
      width="100%"
      height="8px"
      style={{ marginBottom: 8, marginTop: 16 }}
    />
    <Skeleton animation="pulse" width="80%" height="8px" />
  </>
)
export const TitleSkeleton = () => <Skeleton animation="pulse" width="100%" height="25px" />
export const CardSkeleton = () => (
  <Skeleton
    animation="pulse"
    width="100%"
    height="94px"
    style={{ marginBottom: 8, marginTop: 16 }}
  />
)

export const ButtonSkeleton = () => (
  <Skeleton
    animation="pulse"
    width="100%"
    height="48px"
    role="main"
    data-testid="button-skeleton"
  />
)
export const PageSkeleton = () => (
  <S.Container role="main" data-testid="loadding">
    <S.SkeletonVideo />
    {textSkeleton()}
  </S.Container>
)

export const CompanyInfoSkeleton = () => (
  <S.ContainerWhitoutScroll role="main" data-testid="loadding">
    <Header title="Dados da empresa" onBackClick={() => null} />
    <S.CustomContainerColum>
      <TitleSkeleton />
      <CardSkeleton />
      <CardSkeleton />
    </S.CustomContainerColum>
    <S.ContainerButton>
      <ButtonSkeleton />
    </S.ContainerButton>
  </S.ContainerWhitoutScroll>
)
