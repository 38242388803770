export const portabilityForm: PortabilityForm = {
  companyInfoPage: {
    header: 'Dados da empresa',
    title: 'Qual é o CNPJ da empresa em que você trabalha?',
    description:
      'A empresa pode ter diferentes CNPJs, confira seu holerite, contrato ou carteira de trabalho.',
    placeHolder: '00.000.000/0000-00',
    companySocialNameDescription: 'Razão Social',
    companyFantasyNameDescription: 'Nome fantasia',
    invalidCnpjErrorMessage: 'Informe um CNPJ válido',
    button: {
      title: 'Confirmar',
    },
    linkButton: {
      title: 'Saiba como encontrar o CNPJ correto',
    },
    bottomSheet: {
      id: 'CONTINUAR',
      title: 'Essa é a empresa na qual você trabalha?',
      description: '',
      buttons: [
        {
          title: 'Sim, continuar',
        },
        {
          title: 'Não, alterar dados',
        },
      ],
    },
    maximumAttemptsBottomSheet: {
      id: 'MAXIMUM_ATTEMPTS',
      title: '',
      description:
        'Devido ao excesso de tentativas, a consulta do CNPJ está temporariamente indisponível. Tente novamente mais tarde.',
      buttons: [
        {
          title: 'Entendi',
        },
      ],
    },
    maximumAttempts: '5',
    maximumAttemptsTimer: '30',
  },
  originBank: {
    header: 'Banco da conta salário',
    title: 'Qual o banco da sua conta salário?',
    description: 'Informe o banco escolhido por sua empresa para depositar seu salário.',
    placeHolder: 'Selecione o banco',
    button: {
      title: 'Continuar',
    },
    bottomSheet: {
      title: 'Qual o banco da sua conta salário?',
      buttons: [],
    },
    alert: {
      type: 'grayscale',
      title: 'Importante',
      description:
        'Caso já tenha feito uma portabilidade, seu banco salário é diferente daquele no qual você recebe mensalmente.',
    },
  },
  portabilityTips: {
    header: 'Ajuda',
    title: 'Onde você encontra o CNPJ da empresa em que trabalha:',
    tips: [
      {
        title: 'Na sua folha de pagamento (holerite)',
        description: 'Normalmente está próximo as informações do seu empregador.',
      },
      {
        title: 'Com o RH da empresa',
        description: 'Solicite-o a alguém do departamento de Recursos Humanos da empresa.',
      },
      {
        title: 'No seu contrato de trabalho',
        description: 'Estará próximo a outras informações do seu empregador.',
      },
      {
        title: 'Na sua carteira de trabalho',
        description:
          'No aplicativo da Carteira de Trabalho Digital, estará logo na página inicial. Já em sua carteira física, ela estará na página na qual a empresa te registrou.',
      },
    ],
    button: {
      title: 'Entendi',
    },
  },
  resume: {
    header: 'Revisão de dados',
    description: 'Revise os dados sobre a portabilidade e confirme sua solicitação..',
    button: {
      title: 'Confirmar',
    },
    sections: [
      {
        title: 'Dados da empresa',
        textLink: 'Editar',
        labels: ['CNPJ do empregador', 'Nome do empregador'],
      },
      {
        title: 'Banco de origem',
        textLink: 'Editar',
        labels: ['Banco de origem'],
      },
    ],
  },
}
