import { IWbUserInfo } from '@interco/inter-webview-bridge'
import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const getGetMyPortabilityForm = async (nrData: dataNewRelic) => {
  const response = await api<PortabilityForm>(
    `${ENDPOINTS.PORTABILITY_FORM}`,
    {
      method: 'GET',
      headers: {},
    },
    nrData,
  )
  if (!response) {
    return undefined
  }
  const resp: PortabilityForm = response.response
  return resp
}

export const validatePortability = async (
  {
    company,
    user,
    bank,
  }: {
    company: Company
    user?: IWbUserInfo
    bank: Bank
  },
  nrData: dataNewRelic,
) => {
  const response = await api<{ feedback?: Feedback; bottomSheet?: BottomSheet }>(
    `${ENDPOINTS.VALIDATE_PORTABILITY}`,
    {
      method: 'POST',
      headers: {
        cnpj: company.cnpj,
        cpf: user?.cpf || '',
        bankCode: bank.bankCode,
      },
    },
    nrData,
  )
  if (!response) {
    return undefined
  }
  const resp: { feedback?: Feedback; bottomSheet?: BottomSheet } = response.response
  return resp
}
export const requestPortability = async (
  {
    company,
    user,
    bank,
  }: {
    company: Company
    user?: IWbUserInfo
    bank: Bank
  },
  nrData: dataNewRelic,
) => {
  const response = await api<{ feedback: Feedback }>(
    `${ENDPOINTS.REQUEST_PORTABILITY}`,
    {
      method: 'POST',
      headers: {
        empresaCnpj: company.cnpj,
        clienteCpf: user?.cpf || '',
      },
      body: {
        empresaNome: company.name,
        clienteNome: user?.name,
        clienteEmail: user?.email,
        destinoBancoConta: user?.account,
        bancoCodigo: bank.bankCode,
        origem: 'MOBILE',
        tipoAutenticacao: 'Nenhum',
      },
    },
    nrData,
  )
  if (!response) {
    return undefined
  }
  const resp: Feedback = response.response.feedback
  return resp
}
