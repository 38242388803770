import styled from 'styled-components'

import { ContainerColum } from '../Containers'
import { Button as ComponentButton } from '../Buttons'

export const ContainerButton = styled(ContainerColum)`
  align-items: center;
  width: 100%;
`
export const ContainerCenter = styled(ContainerColum)`
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ContainerCenterInfo = styled(ContainerColum)`
height: 100%;
width: 100%;
align-items: center;
justify-content: center;
}`

export const Button = styled(ComponentButton)`
  margin-top: 48px;
`

export const ContainerMinHeight = styled.div`
  min-height: 500px;
  fle
`
