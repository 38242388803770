import React, { SVGAttributes } from 'react'

export const InterOneCard = ({ width = 48, height = 76 }: SVGAttributes<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.9994 2.82315V73.1768C47.9994 74.7369 46.7363 76 45.1763 76H2.89151C1.33143 76 0.0683594 74.7369 0.0683594 73.1768V2.82315C0.0683594 1.26307 1.33143 0 2.89151 0H45.1763C46.7332 0 47.9994 1.26307 47.9994 2.82315Z"
      fill="#8C8C8A"
    />
    <path d="M39.996 65.9448H36.8164V71.6568H39.996V65.9448Z" fill="#EA5B0C" />
    <path
      d="M37.0169 68.8028C37.0169 67.6429 37.5609 66.6112 38.405 65.9453C37.786 65.4576 37.0075 65.1699 36.1602 65.1699C34.1531 65.1699 32.5273 66.7957 32.5273 68.8028C32.5273 70.81 34.1531 72.4357 36.1602 72.4357C37.0075 72.4357 37.7891 72.145 38.405 71.6604C37.5609 70.9944 37.0169 69.9596 37.0169 68.8028Z"
      fill="#DE0716"
    />
    <path
      d="M43.9352 71.0538V70.9381H43.9821V70.9131H43.8633V70.9381H43.9102V71.0538H43.9352ZM44.1697 71.0538V70.9131H44.1322L44.0884 71.01L44.0446 70.9131H44.0071V71.0538H44.0321V70.9475L44.0728 71.0381H44.1009L44.1415 70.9475V71.0538H44.1697Z"
      fill="white"
    />
    <path
      d="M44.2839 68.8028C44.2839 70.81 42.6582 72.4357 40.651 72.4357C39.8038 72.4357 39.0222 72.145 38.4062 71.6604C39.2504 70.9944 39.7944 69.9627 39.7944 68.8028C39.7944 67.6429 39.2504 66.6112 38.4062 65.9453C39.0253 65.4576 39.8038 65.1699 40.651 65.1699C42.6582 65.1699 44.2839 66.7957 44.2839 68.8028Z"
      fill="#F59E00"
    />
    <path
      d="M37.1543 13.337C37.1449 14.078 36.9948 14.7001 36.6822 15.2816C36.6697 15.3035 36.6603 15.3254 36.6478 15.3442C36.5759 15.4661 36.4727 15.5192 36.3445 15.4473C36.2132 15.3754 36.207 15.2598 36.2757 15.1347C36.4852 14.7533 36.6259 14.3531 36.6853 13.9217C36.7916 13.1557 36.6634 12.4304 36.2976 11.7457C36.2883 11.73 36.2789 11.7144 36.2726 11.6988C36.2132 11.58 36.2195 11.4705 36.3414 11.3986C36.4696 11.3236 36.5759 11.3674 36.6478 11.4924C36.8948 11.927 37.0511 12.396 37.1168 12.8931C37.1355 13.0681 37.1605 13.2432 37.1543 13.337Z"
      fill="#FF6B00"
    />
    <path
      d="M36.2536 13.4027C36.2567 13.9498 36.1285 14.4344 35.8815 14.8878C35.7877 15.0597 35.672 15.1097 35.5501 15.041C35.4313 14.9722 35.4188 14.8565 35.5095 14.6783C35.9409 13.8404 35.9409 12.9994 35.5032 12.1615C35.4344 12.0302 35.4032 11.9083 35.547 11.8145C35.6627 11.7363 35.7908 11.7926 35.8815 11.9583C36.1316 12.4148 36.2629 12.9025 36.2536 13.4027Z"
      fill="#FF6B00"
    />
    <path
      d="M35.3691 13.4241C35.3628 13.8055 35.2722 14.1651 35.0815 14.4933C34.9939 14.6465 34.8751 14.6965 34.7594 14.6246C34.625 14.5402 34.6312 14.4245 34.7032 14.2964C35.0283 13.7117 35.0252 13.124 34.7 12.5393C34.6281 12.4111 34.6281 12.2892 34.7657 12.2142C34.9032 12.1391 35.0033 12.2079 35.0783 12.3361C35.269 12.6738 35.3628 13.0364 35.3691 13.4241Z"
      fill="#FF6B00"
    />
    <path
      d="M34.5312 13.4309C34.5218 13.6841 34.4561 13.9249 34.3092 14.1406C34.2248 14.2625 34.1153 14.3157 33.9872 14.2281C33.8652 14.1468 33.8684 14.0312 33.9465 13.9124C34.1591 13.5841 34.156 13.2558 33.9434 12.9307C33.8652 12.8087 33.8684 12.6962 33.9903 12.618C34.1122 12.5399 34.2216 12.5836 34.2998 12.6993C34.453 12.915 34.5218 13.162 34.5312 13.4309Z"
      fill="#FF6B00"
    />
    <path
      d="M6.16744 66.4705H6.38629C6.6489 66.4705 6.85837 66.2579 6.85837 65.9984V65.7358C6.85837 65.4731 6.64578 65.2637 6.38629 65.2637H6.16744C5.90482 65.2637 5.69535 65.4763 5.69535 65.7358V65.9984C5.69222 66.2579 5.90482 66.4705 6.16744 66.4705Z"
      fill="white"
    />
    <path
      d="M22.4777 66.9922C21.8336 66.9922 21.399 67.3861 21.1677 67.927L21.1427 67.0703H20.0703V71.4911H21.1833V69.0994C21.1833 68.2865 21.621 67.8488 22.4183 67.8488C22.5308 67.8488 22.659 67.8582 22.734 67.8645V67.0172C22.6684 67.0016 22.5558 66.9922 22.4777 66.9922Z"
      fill="white"
    />
    <path
      d="M17.3088 66.958C15.9207 66.958 15.0547 67.874 15.0547 69.2184V69.331C15.0547 70.6941 15.9019 71.6007 17.3088 71.6007C18.4468 71.6007 19.2097 71.0192 19.4254 70.0938H18.3187C18.1905 70.4721 17.8716 70.7378 17.3088 70.7378C16.6398 70.7378 16.2552 70.3345 16.1958 69.5467H19.4504C19.5286 68.0116 18.7908 66.958 17.3088 66.958ZM16.2208 68.7838C16.324 68.1398 16.6835 67.824 17.2995 67.824C17.9247 67.824 18.2499 68.1836 18.3093 68.7838H16.2208Z"
      fill="white"
    />
    <path
      d="M13.4314 70.1378V67.893H14.5975V67.0708H13.4314V65.5889H12.3184V67.0708V67.893V70.2504C12.3184 71.1414 12.8061 71.6041 13.8347 71.6041C14.091 71.6041 14.4099 71.5603 14.6131 71.4916V70.6255C14.4943 70.6693 14.288 70.7193 14.0817 70.7193C13.5939 70.7193 13.4314 70.5411 13.4314 70.1378Z"
      fill="white"
    />
    <path
      d="M10.1351 66.9268C9.41604 66.9268 8.96271 67.3051 8.73761 67.724L8.70322 67.0393H7.63086V71.4601H8.74386V69.0089C8.74386 68.2711 9.13779 67.8428 9.72869 67.8428C10.3196 67.8428 10.5603 68.2211 10.5603 68.8964V71.4569H11.6671V68.6119C11.6702 67.6208 11.2325 66.9268 10.1351 66.9268Z"
      fill="white"
    />
    <path d="M5.01172 67.0679V67.8057H5.74955V71.5074H6.86881V67.0679H5.01172Z" fill="white" />
    <path
      d="M5.27734 47.152V45.3262H5.60249L6.14336 46.5455H6.184L6.72175 45.3262H7.05002V47.152H6.80616V45.595L6.82492 45.6013L6.30593 46.7768H6.0058L5.48681 45.6013L5.50557 45.595V47.152H5.27734Z"
      fill="white"
    />
    <path
      d="M7.41016 47.1524L8.06671 45.3265H8.48252L9.16095 47.1524H8.90771L8.29493 45.486L8.41374 45.5454H8.11985L8.24804 45.486L7.65402 47.1524H7.41016ZM7.80096 46.6052L7.88225 46.3864H8.67011L8.75139 46.6052H7.80096ZM8.12923 45.1796L8.44813 44.77H8.7295L8.35121 45.1796H8.12923Z"
      fill="white"
    />
    <path
      d="M9.51953 47.1523V45.3171H9.7634V47.1523H9.51953ZM9.67273 46.4895V46.2707H10.1636C10.2417 46.2707 10.3074 46.255 10.3605 46.2238C10.4168 46.1925 10.4575 46.1487 10.4887 46.0924C10.52 46.0362 10.5325 45.9736 10.5325 45.9017C10.5325 45.8298 10.5169 45.7673 10.4887 45.711C10.4575 45.6547 10.4168 45.611 10.3605 45.5797C10.3043 45.5484 10.2386 45.5297 10.1636 45.5297H9.67273V45.314H10.1167C10.2511 45.314 10.3668 45.3327 10.4668 45.3734C10.5669 45.414 10.645 45.4765 10.6982 45.5578C10.7513 45.6391 10.7795 45.7485 10.7795 45.8798V45.9205C10.7795 46.0518 10.7513 46.1612 10.6951 46.2425C10.6388 46.3238 10.5606 46.3863 10.4637 46.427C10.3637 46.4676 10.248 46.4864 10.1136 46.4864H9.67273V46.4895ZM10.6482 47.1523L10.0416 46.3394H10.3293L10.9452 47.1523H10.6482Z"
      fill="white"
    />
    <path
      d="M12.0879 47.1957C11.9222 47.1957 11.7784 47.1676 11.6627 47.1082C11.5439 47.0487 11.4501 46.9737 11.3782 46.8799C11.3063 46.7861 11.2532 46.6861 11.2219 46.5767C11.1875 46.4672 11.1719 46.3609 11.1719 46.2609V46.2046C11.1719 46.0952 11.1875 45.9858 11.2219 45.8732C11.2563 45.7607 11.3094 45.6637 11.3813 45.5731C11.4533 45.4824 11.547 45.4105 11.6627 45.3542C11.7784 45.298 11.916 45.2729 12.0754 45.2729C12.238 45.2729 12.3787 45.3011 12.4943 45.3574C12.61 45.4136 12.7038 45.4918 12.7726 45.595C12.8414 45.6981 12.882 45.8169 12.8977 45.9545H12.6475C12.635 45.8513 12.6006 45.7669 12.5475 45.6981C12.4943 45.6294 12.4287 45.5793 12.3474 45.5481C12.2661 45.5137 12.1755 45.4981 12.0754 45.4981C11.966 45.4981 11.8722 45.5168 11.7909 45.5543C11.7096 45.5918 11.6408 45.645 11.5877 45.7106C11.5345 45.7794 11.4939 45.8576 11.4658 45.9451C11.4376 46.0327 11.4251 46.1296 11.4251 46.2328C11.4251 46.3297 11.4376 46.4235 11.4658 46.5141C11.4939 46.6017 11.5345 46.6798 11.5908 46.7486C11.6471 46.8174 11.7159 46.8705 11.8003 46.9112C11.8847 46.9518 11.9816 46.9706 12.0942 46.9706C12.2505 46.9706 12.3818 46.9331 12.4881 46.8518C12.5944 46.7736 12.66 46.6611 12.6819 46.511H12.932C12.9164 46.6361 12.8726 46.7517 12.807 46.8549C12.7413 46.9581 12.6475 47.0394 12.5256 47.1019C12.4037 47.1644 12.2599 47.1957 12.0879 47.1957Z"
      fill="white"
    />
    <path d="M13.3125 47.152V45.3262H13.5564V47.152H13.3125Z" fill="white" />
    <path
      d="M13.9258 47.152L14.5823 45.3262H14.9981L15.6766 47.152H15.4233L14.8106 45.4856L14.9294 45.545H14.6355L14.7637 45.4856L14.1696 47.152H13.9258ZM14.3166 46.6049L14.3979 46.386H15.1857L15.267 46.6049H14.3166Z"
      fill="white"
    />
    <path
      d="M17.4481 47.1957C17.2855 47.1957 17.1448 47.1676 17.026 47.1082C16.9072 47.0487 16.8103 46.9737 16.7321 46.8768C16.654 46.783 16.5977 46.6798 16.5602 46.5704C16.5227 46.461 16.5039 46.3578 16.5039 46.2578V46.2015C16.5039 46.0921 16.5227 45.9826 16.5602 45.8732C16.5977 45.7638 16.6571 45.6637 16.7353 45.5731C16.8134 45.4824 16.9103 45.4105 17.0291 45.3542C17.1479 45.298 17.2855 45.2729 17.445 45.2729C17.6044 45.2729 17.7451 45.3011 17.8608 45.3542C17.9796 45.4105 18.0765 45.4824 18.1547 45.5731C18.2328 45.6637 18.2922 45.7638 18.3297 45.8732C18.3673 45.9826 18.386 46.0921 18.386 46.2015V46.2578C18.386 46.3578 18.3673 46.461 18.3297 46.5704C18.2922 46.6798 18.2359 46.7799 18.1578 46.8768C18.0796 46.9706 17.9827 47.0487 17.8639 47.1082C17.7513 47.1676 17.6107 47.1957 17.4481 47.1957ZM17.4481 46.9706C17.5544 46.9706 17.6513 46.9518 17.7357 46.9112C17.8233 46.8705 17.8952 46.8174 17.9546 46.7486C18.014 46.6798 18.0609 46.6017 18.0921 46.5141C18.1234 46.4266 18.139 46.3359 18.139 46.239C18.139 46.1358 18.1234 46.042 18.0921 45.9545C18.0609 45.867 18.014 45.7888 17.9546 45.72C17.8952 45.6512 17.8233 45.5981 17.7357 45.5606C17.6513 45.5231 17.5544 45.5043 17.4481 45.5043C17.3418 45.5043 17.2449 45.5231 17.1605 45.5606C17.076 45.5981 17.001 45.6512 16.9416 45.72C16.8822 45.7888 16.8353 45.867 16.804 45.9545C16.7728 46.042 16.7571 46.139 16.7571 46.239C16.7571 46.3359 16.7728 46.4266 16.804 46.5141C16.8353 46.6017 16.8822 46.6798 16.9416 46.7486C17.001 46.8174 17.076 46.8737 17.1605 46.9112C17.2449 46.9487 17.3418 46.9706 17.4481 46.9706Z"
      fill="white"
    />
    <path
      d="M18.8184 47.152V45.3262H19.0622V47.152H18.8184ZM19.0153 47.152V46.9332H19.8501V47.152H19.0153Z"
      fill="white"
    />
    <path d="M20.209 47.152V45.3262H20.4528V47.152H20.209Z" fill="white" />
    <path
      d="M21.4382 47.152L20.8066 45.3262H21.0599L21.6289 46.9926L21.5101 46.9332H21.8102L21.682 46.9926L22.2041 45.3262H22.448L21.8602 47.152H21.4382Z"
      fill="white"
    />
    <path
      d="M22.8105 47.152V45.3262H23.0544V47.152H22.8105ZM23.0044 45.545V45.3262H23.8641V45.545H23.0044ZM23.0044 46.3329V46.114H23.811V46.3329H23.0044ZM23.0044 47.152V46.9332H23.8892V47.152H23.0044Z"
      fill="white"
    />
    <path d="M24.3418 47.152V45.3262H24.5857V47.152H24.3418Z" fill="white" />
    <path
      d="M25.1445 47.1523V45.3171H25.3884V47.1523H25.1445ZM25.2977 46.4895V46.2707H25.7886C25.8667 46.2707 25.9324 46.255 25.9855 46.2238C26.0418 46.1925 26.0825 46.1487 26.1137 46.0924C26.145 46.0362 26.1575 45.9736 26.1575 45.9017C26.1575 45.8298 26.1419 45.7673 26.1137 45.711C26.0825 45.6547 26.0418 45.611 25.9855 45.5797C25.9293 45.5484 25.8636 45.5297 25.7886 45.5297H25.2977V45.314H25.7417C25.8761 45.314 25.9918 45.3327 26.0918 45.3734C26.1919 45.414 26.27 45.4765 26.3232 45.5578C26.3763 45.6391 26.4045 45.7485 26.4045 45.8798V45.9205C26.4045 46.0518 26.3763 46.1612 26.3201 46.2425C26.2638 46.3238 26.1856 46.3863 26.0887 46.427C25.9887 46.4676 25.873 46.4864 25.7386 46.4864H25.2977V46.4895ZM26.2732 47.1523L25.6666 46.3394H25.9543L26.5702 47.1523H26.2732Z"
      fill="white"
    />
    <path
      d="M26.7266 47.152L27.3831 45.3262H27.7989L28.4773 47.152H28.2241L27.6113 45.4856L27.7301 45.545H27.4363L27.5644 45.4856L26.9704 47.152H26.7266ZM27.1174 46.6049L27.1986 46.386H27.9865L28.0678 46.6049H27.1174Z"
      fill="white"
    />
    <path
      d="M31.5957 9.57945V17.2642C31.5957 17.8113 31.1517 18.2552 30.6046 18.2552H25.7118C25.1647 18.2552 24.7207 17.8113 24.7207 17.2642V9.57945C24.7207 9.03233 25.1647 8.58838 25.7118 8.58838H30.6046C31.1517 8.58838 31.5957 9.03233 31.5957 9.57945Z"
      fill="#B2B2B2"
    />
    <path
      d="M31.5957 12.5839L30.6796 13.4218L31.5957 14.2597V14.3691L30.6046 13.4625H29.576C29.4353 13.4625 29.3197 13.5781 29.3197 13.7188V15.0601L30.1669 16.2043C30.2076 16.2637 30.2326 16.3325 30.2326 16.4044V18.2552H30.1544V16.4044C30.1544 16.3481 30.1357 16.295 30.1044 16.2512L29.2603 15.1101H28.1973V18.2552H28.1191V15.1101H27.0561L26.2151 16.2512C26.1807 16.295 26.1651 16.3481 26.1651 16.4044V18.2552H26.0869V16.4044C26.0869 16.3325 26.1088 16.2606 26.1495 16.2043L26.9967 15.0601V13.4593H25.7118L24.7207 14.366V14.2597L25.6399 13.4218L24.7207 12.5839V12.4745L25.7118 13.3812H26.9967V11.7836L26.1495 10.6362C26.1088 10.5799 26.0869 10.5111 26.0869 10.4392V8.58838H26.1651V10.4392C26.1651 10.4924 26.1807 10.5455 26.2151 10.5893L27.0561 11.7304H28.1191V8.58838H28.1973V11.7304H29.1321C29.2134 11.7304 29.2884 11.6929 29.3384 11.6273L30.1044 10.5893C30.1357 10.5455 30.1544 10.4924 30.1544 10.4392V8.58838H30.2326V10.4392C30.2326 10.5111 30.2076 10.5799 30.1669 10.6362L29.4009 11.6742C29.3384 11.7586 29.2384 11.8086 29.1321 11.8086H27.0749V15.0319H29.2415V13.7188C29.2415 13.5344 29.3916 13.3843 29.576 13.3843H30.6046L31.5957 12.4776V12.5839V12.5839Z"
      fill="#666666"
    />
  </svg>
)
