import React, { useEffect } from 'react'

import { useNavigate, useLocation } from 'react-router'
import { useQuery } from 'react-query'

import { Header } from '@/components'
import useGlobalState from '@/contexts/global-state'
import { VideoPlayer } from '@/components/VideoPlayer'
import { ApiService, BridgeService } from '@/services'
import { TopcCard } from '@/components/TopcCard'
import { RoutesNames } from '@/routes/routes'
import { InterOne } from '@/components/InterOne'
import { Icon } from '@/components/IconComponent'
import { setOboardingText } from '@/contexts/global-state/reducer/actions'
import * as ENDPOINTS from '@/common/constants/endpoints'
import { PageSkeleton } from '@/components/PageSkeletons'
import { Babi } from '@interco/inter-ui/components/Babi'
import { requestAnalytics } from '@/services/bridge'
import { LogEventPage } from '@/utils/tagEvent'

import * as S from './styles'

const Onboarding = () => {
  const [{ onboarding, user }, dispatch] = useGlobalState()
  const navigate = useNavigate()

  const location = useLocation()

  const isFaq = location.pathname === RoutesNames.FAQ_WITH_VIDEO
  const { isError, isFetching } = useQuery<OnboardingPage | undefined>(
    ENDPOINTS.ONBOARDING_PAGE,
    async () => {
      const nrData: dataNewRelic = {
        isErro: false,
        account: user?.account || '',
        action: `OBTER TEXTOS ONBOARDING`,
        status: '',
        requestData: '',
      }
      const response = await ApiService.Onboarding.getOnboarding(user?.cpf || '', nrData)

      return response
    },
    {
      enabled: onboarding === undefined,
      retry: false,

      onSuccess: (response) => {
        if (response) dispatch(setOboardingText({ onboarding: response }))
      },

      onError: () => {
        navigate(RoutesNames.FEEDBACK_ERROR_LOAD, { replace: true })
      },
    },
  )

  const { header, videoUrl, title, description, benefitsTitle, benefits, interOne, button } =
    onboarding || {
      header: '',
      videoUrl: '',
      benefits: [],
      interOne: {
        status: false,
        title: '',
        description: '',
      },
      button: { title: '' },
    }

  useEffect(() => {
    LogEventPage(isFaq ? 'FAQ_WITH_VIDEO' : `ONBOARDING`, user?.userTestingType)
  }, [isFaq, user?.userTestingType])
  if (isError) return <div data-testid="error" />
  if (isFetching)
    return (
      <S.Container>
        <Header
          title="Portabilidade de Salário"
          onBackClick={() => (isFaq ? navigate(-1) : BridgeService.interWbNavigate.requestGoBack())}
        />
        <PageSkeleton />
      </S.Container>
    )
  return (
    <S.Container>
      <Header
        title={header}
        onBackClick={() => (isFaq ? navigate(-1) : BridgeService.interWbNavigate.requestGoBack())}
      />

      <VideoPlayer
        url={videoUrl}
        onPlay={() => requestAnalytics('C_PSalario_Home_T_AssistirVideo')}
      />
      <S.CustomContainerColum>
        <S.Title>{title}</S.Title>
        <S.Info>{description}</S.Info>

        <S.SubTitle>{benefitsTitle}</S.SubTitle>

        {benefits.map((benefit) => (
          <TopcCard
            icon={
              <Icon
                name={benefit.icon.id}
                height={20}
                width={20}
                color={`var(${benefit.icon.color})`}
              />
            }
            title={benefit.title}
            info={benefit.description}
          />
        ))}
        {interOne.status}
        {interOne.status && <InterOne title={interOne.title} info={interOne.description} />}
        {isFaq ? (
          <S.BabiMargin>
            <Babi />
          </S.BabiMargin>
        ) : (
          <S.Button
            data-testid="continue"
            onClick={() => {
              requestAnalytics('C_PSalario_Home_T_Continuar')
              navigate(RoutesNames.COMPANY_INFO, { replace: false })
            }}
          >
            {button.title}
          </S.Button>
        )}
      </S.CustomContainerColum>
    </S.Container>
  )
}

export default Onboarding
