import React from 'react'

import * as S from './styles'
import { ButtonSecondary } from '../Buttons'
import { BoxWithIconAndText } from '../BoxWithIconAndText'

type Button = {
  title: string
  action: () => void
}
export type BoxWithButtonsProps = {
  icon: FeedbackStatusIcon
  title: string
  description: string
  subDescription?: string
  primaryButton: Button
  secondaryButton?: Button
}

export const BoxWithButtons = ({
  icon,
  title,
  description,
  subDescription,
  primaryButton,
  secondaryButton,
}: BoxWithButtonsProps) => (
  <S.ContainerCenter>
    <BoxWithIconAndText
      icon={icon}
      title={title}
      description={description}
      subDescription={subDescription}
    />
    <S.ContainerButton>
      <S.Button onClick={primaryButton.action}>{primaryButton.title}</S.Button>
      {secondaryButton && secondaryButton.title !== '' && (
        <ButtonSecondary onClick={secondaryButton.action}>{secondaryButton.title}</ButtonSecondary>
      )}
    </S.ContainerButton>
  </S.ContainerCenter>
)
