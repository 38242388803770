import { createReducer } from 'cdpro-redux-utils'

import { RootState } from './types'
import {
  resetData,
  resetHomeData,
  setBank,
  setCompany,
  setFeedback,
  setHome,
  setListBanks,
  setOboardingText,
  setPortabilityFormMetadata,
  setSkipHomePage,
  setUser,
} from './actions'

export const INITIAL_STATE: RootState = {
  skipHomePage: true,
}

const { reducer, addCase } = createReducer<RootState>(INITIAL_STATE)

addCase(setSkipHomePage, (draft, action) => {
  draft.skipHomePage = action.payload
})

addCase(setCompany, (draft, action) => {
  const { company } = action.payload

  draft.company = company
})

addCase(setPortabilityFormMetadata, (draft, action) => {
  const { companyInfoPage, originBank, portabilityTips, resume } = action.payload.metadata

  draft.companyText = companyInfoPage
  draft.bankText = originBank
  draft.resumeText = resume
  draft.faq = { ...draft.faq, portabilityTips }
})

addCase(setUser, (draft, action) => {
  const { user } = action.payload
  draft.user = user
})

addCase(setFeedback, (draft, action) => {
  const { feedback } = action.payload
  draft.feedback = feedback
})

addCase(setBank, (draft, action) => {
  const { bank } = action.payload

  draft.bank = bank
})
addCase(setListBanks, (draft, action) => {
  const { list } = action.payload

  draft.listBanks = list
})

addCase(setHome, (draft, action) => {
  const { home } = action.payload

  draft.faq = { ...draft.faq, homeFaq: home.homeFaq }
  draft.home = home
})

addCase(setOboardingText, (draft, action) => {
  const { onboarding } = action.payload

  draft.onboarding = onboarding
})
addCase(resetData, (draft) => {
  const { bank, company } = INITIAL_STATE

  draft.company = company
  draft.bank = bank
})
addCase(resetHomeData, (draft) => {
  const { home } = INITIAL_STATE

  draft.home = home
})
export default reducer
