const onboarding: OnboardingPage = {
  header: 'Portabilidade Salario',
  videoUrl: 'https://www.youtube.com/watch?v=bkgh09XXtS0',
  title: 'Traga o seu salário para o Inter e aproveite mais',
  description:
    'Faça a portabilidade do seu salário para o Inter e aproveite todos os benefícios da sua conta completa e 100% gratuita.',
  benefitsTitle: 'Você encontra muito mais benefícios aqui',
  benefits: [
    {
      icon: {
        id: 'Card',
        color: '--gray500',
      },
      title: 'Conta Digital 100% gratuita',
      description:
        'Tenha todo seu dinheiro em uma só conta, garanta transações gratuitas e muitas outras vantagens',
    },
    {
      icon: {
        id: 'Investments',
        color: '--gray500',
      },
      title: 'Seu salário pode render muito mais',
      description: "Você pode investir parte do seu salário pela 'Inter Invest' sem custo nenhum",
    },
    {
      icon: {
        id: 'Cashback',
        color: '--gray500',
      },
      title: 'Todas as vantagens do Inter para você',
      description:
        'Aqui, você compra em diversas lojas no Inter Shopping e recebe <b>cashback</b> direto na sua conta',
    },
  ],
  interOne: {
    status: false,
    title: 'A exclusividade de ser Inter One',
    description:
      'Se você recebe um salário acima de R$ 6 mil, pode ser cliente Inter One, com cartão Platinum, atendimento de um advisor e muito mais benefícios!',
  },
  button: {
    title: 'Trazer meu salário',
  },
}
export default onboarding
