import * as ENDPOINTS from '@/common/constants/endpoints'

import { api } from '../api'

export const getOnboarding = async (cpf: string, nrData: dataNewRelic) => {
  const response = await api<OnboardingPage>(
    `${ENDPOINTS.ONBOARDING_PAGE}`,
    {
      method: 'GET',
      headers: { cpf },
    },
    nrData,
  )
  if (response === undefined) {
    return undefined
  }
  const resp: OnboardingPage = {
    ...response.response,
  }
  return resp
}
